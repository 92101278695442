import { ThunkAction, Action, configureStore } from '@reduxjs/toolkit';
import candidateProfileReducer from "../components/CandidateProfile/candidateProfileslice";
import currentUserReducer from "./slices/currentUserSlice";
import candidateFeedbackReducer from "../components/CandidateFeedback/candidateFeedbackSlice";
import candidateSliceReducer from "../components/Candidates/candidateslice";
import superDaySliceReducer from '../components/SuperDay/superDayslice';
import preSuperDaySliceReducer from '../components/PreSuperDay/preSuperDayslice';
import employeeProfileSliceReducer from '../components/EmployeeProfile/employeeProfileSlice';
import employeeSliceReducer from "../components/Employees/employeeslice";
import candidateSchedulingSliceReducer from '../components/CdrScheduler/CandidateScheduler/candidateSchedulerSlice';
import lboSchedulingSliceReducer from '../components/CdrScheduler/LboScheduler/lboSchedulerSlice';
import candidatesProgressSliceReducer from '../components/CandidatesProgress/candidatesProgressslice'

export const store = configureStore({
    reducer: {
        currentUser: currentUserReducer,
        // resumeDetails: resumeDetailsReducer,
        candidateProfile: candidateProfileReducer,
        candidates: candidateSliceReducer,
        candidateFeedback: candidateFeedbackReducer,
        superday: superDaySliceReducer,
        preSuperDay: preSuperDaySliceReducer,
        employeeProfile: employeeProfileSliceReducer,
        employee: employeeSliceReducer,
        candidateScheduling: candidateSchedulingSliceReducer,
        lboScheduling: lboSchedulingSliceReducer,
        candidatesProgress: candidatesProgressSliceReducer

    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;