import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import {
    Client, IUpdateResumeDetailsCommand, UpdateResumeDetailsCommand, UpdateResumeProfileOverviewDto,
    UpdateResumeDetailsDto, ICandidateIdentifierDto, ICandidateCurrentEmploymentDto, IResumeDetailsLookupsVm,
    ICandidateEducationDto, ICandidateStandardizedTestsDto, ICandidateOtherConsiderationsDto, ICandidateVerticalInterestDto,
    ICandidateLocationDto, ICandidateDiversityDto, ICandidatePeRecruitingYearDto, ICandidateAffiliationDto,
    ICandidateJobOfferDto, ICandidateCompetitivenessDto, IScoringAndInterviewsLookupsVm, ICandidateInterviewFeedbackDto,
    ICandidateNotesDto, CandidateSemiStructuredNoteDto,
    ISchedulingLookupsVm, ICandidateSchedulingWorkflowDto, UpdateSchedulingDto, IUpdateSchedulingCommand, UpdateSchedulingCommand, UpdateCandidateNotesCommand, IUpdateCandidateNotesCommand, UpdateCandidateNotesDto, IUpdateCandidateNotesDto
} from '../../Api/client-api';
import { cloneDeep } from 'lodash';
import { IResumeDetails } from '../../Interfaces/IResumeDetails';
import { IContactInfo } from '../../Interfaces/IContactInfo';
import { IProfileOverview } from '../../Interfaces/IProfileOverview';
import { IScoringAndInterviews } from '../../Interfaces/IScoringAndInterviews';
import { IScheduling } from '../../Interfaces/IScheduling';
import * as CONTACTINFO from './ResumeDetailsTab/ContactInfo/contactInfoConsts';
import * as _contactInfoHelper from './contactInfoHelper';
import * as _resumeDetailsHelper from './ResumeDetailsTab/resumeDetailsHelper';
import * as _profileOverviewHelper from './profileOverviewHelper';
import * as ContactInfoData from './ResumeDetailsTab/ContactInfo/contactInfoInitialData'
import * as _schedulingHelper from './SchedulingTab/schedulingHelper';

const api = new Client("", axios);

const initialContactInfo: IContactInfo = {
    // START: fields specific to this model
    preferredName: '',
    altNamesCount: 1,
    primaryNameIndex: CONTACTINFO.RESUMENAME,
    preferredEmail: '',
    emailsCount: 2,
    primaryEmailIndex: CONTACTINFO.EMAIL1,
    preferredPhone: '',
    phonesCount: 2,
    primaryPhoneIndex: CONTACTINFO.PHONE1,
    // END

    // START: fields coming from/to DTOs
    candidateNames: [
        ContactInfoData.initialNameResume,
        ContactInfoData.initialNameAlt1,
        ContactInfoData.initialNameAlt2,
        ContactInfoData.initialNameAlt3,
        ContactInfoData.initialNameAlt4
    ],
    candidateEmails: [
        ContactInfoData.initialEmail1,
        ContactInfoData.initialEmail2,
        ContactInfoData.initialEmail3,
        ContactInfoData.initialEmail4,
        ContactInfoData.initialEmail5
    ],
    candidatePhones: [
        ContactInfoData.initialPhone1,
        ContactInfoData.initialPhone2,
        ContactInfoData.initialPhone3,
        ContactInfoData.initialPhone4,
        ContactInfoData.initialPhone5,
    ]

    //END
}

const initialIdentifier: ICandidateIdentifierDto = {
    linkedInUrl: null,
    henkelId: null,
    ocean7Id: null,
    isDuplicateLinkedInUrl: false,
    isDuplicateHenkelId: false,
    isDuplicateOcean7Id: false,
};

const initialEducation: ICandidateEducationDto = {
    schoolLookupId: null,
    undergradOtherSchool: null,
    undergradMajor: null,
    schoolMajorCategoryLookupId: null,
    undergradYear: null,
    undergradGpa: null,
    undergradMaxGpa: null,
}

const initialCurrentEmployment: ICandidateCurrentEmploymentDto = {
    employerFirmLookupId: null,
    employerGroupLookupId: null
}

const initialStandardizedTests: ICandidateStandardizedTestsDto = {
    testGmat: null,
    testSatMath: null,
    testSatVerbal: null,
    testSatTotal1600: null,
    testSatTotal2400: null,
    testAct: null
}

const initialOtherConsiderations: ICandidateOtherConsiderationsDto = {
    leadership: null,
    athletics: null,
    grit: null
}

const initialVerticalInterest: ICandidateVerticalInterestDto = {
    healthcareInterestLevelLookupId: null,
    retailAndConsumerInterestLevelLookupId: null,
    industrialsInterestLevelLookupId: null,
    techInterestLevelLookupId: null,
    financialServicesInterestLevelLookupId: null
}

const initialLocation: ICandidateLocationDto = {
    interviewLocationPreferenceLookupId: null,
    timeZoneLookupId: null,
    homeTownZip: null,
    homeTown: null,
    highSchool: null,
    workAuthorizationLookupId: null
}

const initialDiversity: ICandidateDiversityDto = {
    genderLookupId: null,
    genderAssumed: null,
    raceEthnicityLookupId: null,
    raceEthnicityAssumed: null,
    lgbtq: null,
    lgbtqAssumed: null,
    employerFirmTypeLookupId: null,
    employerFirmTypeLookupName: null,
}

const initialPeRecruitingYear: ICandidatePeRecruitingYearDto = {
    preferredCycle: null,
    alternateCycle: null,
    recruitingYear: null,
    recruitOffCycle: null
}

const initialAffiliation: ICandidateAffiliationDto = {
    girlsWhoInvest: null,
    mlt: null,
    seo: null,
    aifpLookupId: null,
    synergist: null,
    outForUndergrad: null
}

const initialJobOffer: ICandidateJobOfferDto = {
    offerExtended: null,
    offerDateTime: null,
    peEmployerLookupId: null,
    offerAccepted: null,
    verticalTypeLookupId: null,
    incoming: null,
    offTheMarket: null
}

const initialResumeDetails: IResumeDetails = {
    identifier: initialIdentifier,
    contactInfo: initialContactInfo,
    location: initialLocation,
    currentEmployment: initialCurrentEmployment,
    education: initialEducation,
    standardizedTests: initialStandardizedTests,
    otherConsiderations: initialOtherConsiderations,
    verticalInterest: initialVerticalInterest,
    diversity: initialDiversity,
    affiliation: initialAffiliation,
    peRecruitingYear: initialPeRecruitingYear,
    jobOffer: initialJobOffer,
}

const initialCandidateInterviewFeedback: ICandidateInterviewFeedbackDto = {
    interviewTypeName: null,
    candidateScore: null,
    minScore: null,
    interviewerAverageScore: null,
    interviewerName: null,
    feedback: null,
    created: null
}

const initialSuperDaySchedulingWorkflow: ICandidateSchedulingWorkflowDto = {
    eligibility: null,
    schedulingWorkflowStatusLookupId: null,
    arrivalDateTime: null
}

const initialCc1SchedulingWorkflow: ICandidateSchedulingWorkflowDto = {
    eligibility: null,
    schedulingWorkflowStatusLookupId: null,
    arrivalDateTime: null,
    interviewerId: null
}

const initialScoringAndInterviews: IScoringAndInterviews = {
    candidateInterviewFeedbacks: [
        initialCandidateInterviewFeedback
    ]
}

const initialScheduling: IScheduling = {
    superDaySchedulingWorkflow: initialSuperDaySchedulingWorkflow,
    jobOffer: initialJobOffer,
    cc1SchedulingWorkflow: initialCc1SchedulingWorkflow
}

const initialCompetitiveness: ICandidateCompetitivenessDto = {
    isTarget: null,
    isDisqualified: null,
    preSDPriority: null,
    sdPriority: null,
    targetCandidateCount: null,
    candidateCount: null,
    resumeConfidence: null,
    resumeScore: null,
    poolRank: null
}

const initialCandidateNotes: ICandidateNotesDto = {
    profileNote: null,
    semiStructuredNotes: null
}

const initialProfileOverview: IProfileOverview = {
    competitiveness: initialCompetitiveness,
    lastUpdated: null,
    materiallyUpdated: null,
    lastTouchpoint: null,
    ocean7LastLoginTime: null
}

export interface ICandidateProfileState {
    status: string,
    candidateId: number | undefined,
    resumeDetails: IResumeDetails,
    profileOverview: IProfileOverview,
    lookupTables: IResumeDetailsLookupsVm | undefined,
    scoringLookupTables: IScoringAndInterviewsLookupsVm | undefined,
    scoringAndInterviews: IScoringAndInterviews,
    notes: ICandidateNotesDto,
    schedulingLookupTables: ISchedulingLookupsVm | undefined,
    scheduling: IScheduling
};

const initialState: ICandidateProfileState = {
    status: 'idle',
    candidateId: undefined,
    resumeDetails: initialResumeDetails,
    profileOverview: initialProfileOverview,
    lookupTables: undefined,
    scoringLookupTables: undefined,
    scoringAndInterviews: initialScoringAndInterviews,
    notes: initialCandidateNotes,
    schedulingLookupTables: undefined,
    scheduling: initialScheduling
};

export const getResumeDetailLookups = createAsyncThunk(
    'candidateProfile/fetchResumeDetailLookups',
    async () => {
        let response = await (await api.getResumeDetailLookups()).toJSON();

        return response;
    }
)

export const getScoringAndInterviewsLookups = createAsyncThunk(
    'candidateProfile/fetchScoringAndInterviewsLookups',
    async () => {
        let response = await (await api.getScoringAndInterviewsLookups()).toJSON();

        return response;
    }
)

export const getSchedulingLookups = createAsyncThunk(
    'candidateProfile/fetchSchedulingLookups',
    async () => {
        let response = await (await api.getSchedulingLookups()).toJSON();

        return response;
    }
)

export const getCandidateProfileAsync = createAsyncThunk(
    'candidateProfile/fetchResumeDetails',
    async (id: number | undefined) => {
        let response = null;

        if (id !== undefined) {
            response = await (await api.getResumeDetails(id)).toJSON();
        }
        // the value we return becomes the 'fulfilled' action payload
        return response;
    }
);

function buildStateFromGetOrPost(state: any, action: any): void {
    state.candidateId = action.payload.candidateId;

    state.profileOverview.lastUpdated = new Date(action.payload.profileOverview.lastUpdated).toLocaleString();

    if (action.payload.profileOverview?.materiallyUpdated) {
        state.profileOverview.materiallyUpdated = new Date(action.payload.profileOverview.materiallyUpdated).toLocaleString();
    }
    if (action.payload.profileOverview?.lastTouchpoint) {
        state.profileOverview.lastTouchpoint = new Date(action.payload.profileOverview.lastTouchpoint).toLocaleString();
    }

    if (action.payload.profileOverview?.ocean7LastLoginTime) {
        state.profileOverview.ocean7LastLoginTime = action.payload.profileOverview.ocean7LastLoginTime;
    }

    if (action.payload.profileOverview?.competitiveness) {
        state.profileOverview.competitiveness = action.payload.profileOverview.competitiveness;
    }

    if (action.payload.resumeDetails?.contactInfo) {
        if (action.payload.resumeDetails.contactInfo.candidateNames) {
            _contactInfoHelper.setStateForNames(state, action.payload.resumeDetails.contactInfo.candidateNames);
        }
        if (action.payload.resumeDetails.contactInfo.candidateEmails) {
            _contactInfoHelper.setStateForEmails(state, action.payload.resumeDetails.contactInfo.candidateEmails);
        }
        if (action.payload.resumeDetails.contactInfo.candidatePhones) {
            _contactInfoHelper.setStateForPhones(state, action.payload.resumeDetails.contactInfo.candidatePhones);
        }
    }

    if (action.payload.resumeDetails?.identifier) {
        state.resumeDetails.identifier = action.payload.resumeDetails.identifier
    }

    if (action.payload.resumeDetails?.location) {
        state.resumeDetails.location = action.payload.resumeDetails.location;
    }

    if (action.payload.resumeDetails?.currentEmployment) {
        state.resumeDetails.currentEmployment = action.payload.resumeDetails.currentEmployment
    }

    if (action.payload.resumeDetails?.education) {
        state.resumeDetails.education = action.payload.resumeDetails.education
    }

    if (action.payload.resumeDetails?.standardizedTests) {
        state.resumeDetails.standardizedTests = action.payload.resumeDetails.standardizedTests;
    }

    if (action.payload.resumeDetails?.otherConsiderations) {
        state.resumeDetails.otherConsiderations = action.payload.resumeDetails.otherConsiderations;
    }

    if (action.payload.resumeDetails?.verticalInterest) {
        state.resumeDetails.verticalInterest = action.payload.resumeDetails.verticalInterest;
    }

    if (action.payload.resumeDetails?.diversity) {
        state.resumeDetails.diversity = action.payload.resumeDetails.diversity;
    }

    if (action.payload.resumeDetails?.affiliation) {
        state.resumeDetails.affiliation = action.payload.resumeDetails.affiliation;
    }

    if (action.payload.resumeDetails?.peRecruitingYear) {
        state.resumeDetails.peRecruitingYear.preferredCycle = action.payload.resumeDetails.peRecruitingYear.preferredCycle;
        state.resumeDetails.peRecruitingYear.alternateCycle = action.payload.resumeDetails.peRecruitingYear.alternateCycle;
        state.resumeDetails.peRecruitingYear.recruitingYear = action.payload.resumeDetails.peRecruitingYear.recruitingYear;
        state.resumeDetails.peRecruitingYear.recruitOffCycle = action.payload.resumeDetails.peRecruitingYear.recruitOffCycle;
    }

    if (action.payload.resumeDetails?.jobOffer) {
        if (action.payload.resumeDetails?.jobOffer) {
            state.resumeDetails.jobOffer.offerExtended = action.payload.resumeDetails.jobOffer.offerExtended;
            state.resumeDetails.jobOffer.peEmployerLookupId = action.payload.resumeDetails.jobOffer.peEmployerLookupId;
            state.resumeDetails.jobOffer.offerDateTime = (action.payload.resumeDetails.jobOffer.offerDateTime
                ? new Date(action.payload.resumeDetails.jobOffer.offerDateTime).toLocaleString()
                : null);
            state.resumeDetails.jobOffer.offerAccepted = action.payload.resumeDetails.jobOffer.offerAccepted;
            state.resumeDetails.jobOffer.verticalTypeLookupId = action.payload.resumeDetails.jobOffer.verticalTypeLookupId;
            state.resumeDetails.jobOffer.incoming = action.payload.resumeDetails.jobOffer.incoming;
            state.resumeDetails.jobOffer.offTheMarket = action.payload.resumeDetails.jobOffer.offTheMarket;
            state.resumeDetails.jobOffer.peEmployerLookupName = action.payload.resumeDetails.jobOffer.peEmployerLookupName;
        }
    }
    else if (action.payload.scheduling?.jobOffer) {
        if (action.payload.scheduling?.jobOffer) {
            state.resumeDetails.jobOffer.offerExtended = action.payload.scheduling.jobOffer.offerExtended;
            state.resumeDetails.jobOffer.peEmployerLookupId = action.payload.scheduling.jobOffer.peEmployerLookupId;
            state.resumeDetails.jobOffer.offerDateTime = (action.payload.scheduling.jobOffer.offerDateTime
                ? new Date(action.payload.scheduling.jobOffer.offerDateTime).toLocaleString()
                : null);
            state.resumeDetails.jobOffer.offerAccepted = action.payload.scheduling.jobOffer.offerAccepted;
            state.resumeDetails.jobOffer.verticalTypeLookupId = action.payload.scheduling.jobOffer.verticalTypeLookupId;
            state.resumeDetails.jobOffer.offTheMarket = action.payload.scheduling.jobOffer.offTheMarket;

        }
    }

    if (action.payload.scoringAndInterviews?.candidateInterviewFeedbacks) {
        state.scoringAndInterviews.candidateInterviewFeedbacks = action.payload.scoringAndInterviews.candidateInterviewFeedbacks;
    }

    if (action.payload.notes?.profileNote) {
        state.notes.profileNote = action.payload.notes.profileNote;
    }

    if (action.payload.notes?.semiStructuredNotes) {
        state.notes.semiStructuredNotes = action.payload.notes.semiStructuredNotes;
    }

    if (action.payload.scheduling?.superDaySchedulingWorkflow) {
        state.scheduling.superDaySchedulingWorkflow.eligibility = action.payload.scheduling.superDaySchedulingWorkflow.eligibility;
        state.scheduling.superDaySchedulingWorkflow.schedulingWorkflowStatusLookupId = action.payload.scheduling.superDaySchedulingWorkflow.schedulingWorkflowStatusLookupId;
        state.scheduling.superDaySchedulingWorkflow.arrivalDateTime = (action.payload.scheduling.superDaySchedulingWorkflow.arrivalDateTime
            ? new Date(action.payload.scheduling.superDaySchedulingWorkflow.arrivalDateTime).toLocaleString()
            : null);
    }

    if (action.payload.scheduling?.cc1SchedulingWorkflow) {
        state.scheduling.cc1SchedulingWorkflow.eligibility = action.payload.scheduling.cc1SchedulingWorkflow.eligibility;
        state.scheduling.cc1SchedulingWorkflow.schedulingWorkflowStatusLookupId = action.payload.scheduling.cc1SchedulingWorkflow.schedulingWorkflowStatusLookupId;
        state.scheduling.cc1SchedulingWorkflow.arrivalDateTime = (action.payload.scheduling.cc1SchedulingWorkflow.arrivalDateTime
            ? new Date(action.payload.scheduling.cc1SchedulingWorkflow.arrivalDateTime).toLocaleString()
            : null);
        state.scheduling.cc1SchedulingWorkflow.interviewerId = action.payload.scheduling.cc1SchedulingWorkflow.interviewerId;
    }
}

function getOnlyUpdatedDetails(candidateProfile: any): ICandidateProfileState {
    let updatedProfileDetails = cloneDeep(candidateProfile);
    updatedProfileDetails.resumeDetails.identifier = undefined;
    updatedProfileDetails.resumeDetails.currentEmployment = undefined;

    let newProfileNote = candidateProfile.notes.profileNote;
    if (JSON.stringify(newProfileNote) !== JSON.stringify(originalCandidateProfileState.notes.profileNote)) {
        updatedProfileDetails.notes.profileNote = cloneDeep(newProfileNote);
    }
    else {
        updatedProfileDetails.notes.profileNote = null;
    }

    let newNotes = candidateProfile.notes.semiStructuredNotes;
    let updatedNotes: CandidateSemiStructuredNoteDto[] = [];
    if (newNotes) {
        newNotes.forEach((newNote: CandidateSemiStructuredNoteDto) => {
            let oldNote = originalCandidateProfileState.notes.semiStructuredNotes?.find((oldNote: CandidateSemiStructuredNoteDto) => oldNote.semiStructuredNoteHeaderId === newNote.semiStructuredNoteHeaderId);
            if (JSON.stringify(newNote) !== JSON.stringify(oldNote)) {
                updatedNotes?.push(newNote);
            }
        });
    }

    if (updatedNotes.length !== 0) {
        updatedProfileDetails.notes.semiStructuredNotes = cloneDeep(updatedNotes);
    }
    else {
        updatedProfileDetails.notes.semiStructuredNotes = null;
    }

    let newCompetitiveness = candidateProfile.profileOverview.competitiveness;
    if (JSON.stringify(newCompetitiveness) !== JSON.stringify(originalCandidateProfileState.profileOverview.competitiveness)) {
        updatedProfileDetails.profileOverview.competitiveness = cloneDeep(newCompetitiveness);
        if (updatedProfileDetails.profileOverview.competitiveness?.isTarget === false) {
            updatedProfileDetails.profileOverview.competitiveness.preSDPriority = null;
            updatedProfileDetails.profileOverview.competitiveness.sdPriority = null;
        }
    }
    else {
        updatedProfileDetails.profileOverview.competitiveness = null;
    }

    let contactInfo = candidateProfile.resumeDetails.contactInfo;
    if (contactInfo?.candidateNames) {
        let namesToUpdate = _contactInfoHelper.getUpdatedCandidateNames(contactInfo, originalCandidateProfileState.resumeDetails.contactInfo.primaryNameIndex);
        if (namesToUpdate.length === 0) {
            updatedProfileDetails.resumeDetails.contactInfo.candidateNames = null;
        }
        else {
            updatedProfileDetails.resumeDetails.contactInfo.candidateNames = cloneDeep(namesToUpdate);
        }
    }

    if (contactInfo?.candidateEmails) {
        let emailsToUpdate = _contactInfoHelper.getUpdatedCandidateEmails(contactInfo, originalCandidateProfileState.resumeDetails.contactInfo.primaryEmailIndex);
        if (emailsToUpdate.length === 0) {
            updatedProfileDetails.resumeDetails.contactInfo.candidateEmails = null;
        }
        else {
            updatedProfileDetails.resumeDetails.contactInfo.candidateEmails = cloneDeep(emailsToUpdate);
        }
    }

    if (contactInfo?.candidatePhones) {
        let phonesToUpdate = _contactInfoHelper.getUpdatedCandidatePhones(contactInfo, originalCandidateProfileState.resumeDetails.contactInfo.primaryPhoneIndex);
        if (phonesToUpdate.length === 0) {
            updatedProfileDetails.resumeDetails.contactInfo.candidatePhones = null;
        }
        else {
            updatedProfileDetails.resumeDetails.contactInfo.candidatePhones = cloneDeep(phonesToUpdate);
        }
    }

    let newIdentifier = candidateProfile.resumeDetails.identifier;
    if (JSON.stringify(newIdentifier) !== JSON.stringify(originalCandidateProfileState.resumeDetails.identifier)) {
        updatedProfileDetails.resumeDetails.identifier = cloneDeep(newIdentifier);
    }
    else {
        updatedProfileDetails.resumeDetails.identifier = null;
    }

    let newLocation = candidateProfile.resumeDetails.location;
    if (JSON.stringify(newLocation) !== JSON.stringify(originalCandidateProfileState.resumeDetails.location)) {
        updatedProfileDetails.resumeDetails.location = cloneDeep(newLocation);
    }
    else {
        updatedProfileDetails.resumeDetails.location = null;
    }

    let newCurrentEmployment = candidateProfile.resumeDetails.currentEmployment;
    if (JSON.stringify(newCurrentEmployment) !== JSON.stringify(originalCandidateProfileState.resumeDetails.currentEmployment)) {
        updatedProfileDetails.resumeDetails.currentEmployment = cloneDeep(newCurrentEmployment);
    }
    else {
        updatedProfileDetails.resumeDetails.currentEmployment = null;
    }

    let newEducation = candidateProfile.resumeDetails.education;
    if (JSON.stringify(newEducation) !== JSON.stringify(originalCandidateProfileState.resumeDetails.education)) {
        updatedProfileDetails.resumeDetails.education = cloneDeep(newEducation);
    }
    else {
        updatedProfileDetails.resumeDetails.education = null;
    }

    let newTests = candidateProfile.resumeDetails.standardizedTests;
    if (JSON.stringify(newTests) !== JSON.stringify(originalCandidateProfileState.resumeDetails.standardizedTests)) {
        updatedProfileDetails.resumeDetails.standardizedTests = cloneDeep(newTests);
    }
    else {
        updatedProfileDetails.resumeDetails.standardizedTests = null;
    }

    let newConsiderations = candidateProfile.resumeDetails.otherConsiderations;
    if (JSON.stringify(newConsiderations) !== JSON.stringify(originalCandidateProfileState.resumeDetails.otherConsiderations)) {
        updatedProfileDetails.resumeDetails.otherConsiderations = cloneDeep(newConsiderations);
    }
    else {
        updatedProfileDetails.resumeDetails.otherConsiderations = null;
    }

    let newVerticalInterest = candidateProfile.resumeDetails.verticalInterest;
    if (JSON.stringify(newVerticalInterest) !== JSON.stringify(originalCandidateProfileState.resumeDetails.verticalInterest)) {
        updatedProfileDetails.resumeDetails.verticalInterest = cloneDeep(newVerticalInterest);
    }
    else {
        updatedProfileDetails.resumeDetails.verticalInterest = null;
    }

    let newDiversity = candidateProfile.resumeDetails.diversity;
    if (JSON.stringify(newDiversity) !== JSON.stringify(originalCandidateProfileState.resumeDetails.diversity)) {
        updatedProfileDetails.resumeDetails.diversity = cloneDeep(newDiversity);
    }
    else {
        updatedProfileDetails.resumeDetails.diversity = null;
    }

    let newAffiliation = candidateProfile.resumeDetails.affiliation;
    if (JSON.stringify(newAffiliation) !== JSON.stringify(originalCandidateProfileState.resumeDetails.affiliation)) {
        updatedProfileDetails.resumeDetails.affiliation = cloneDeep(newAffiliation);
    }
    else {
        updatedProfileDetails.resumeDetails.affiliation = null;
    }

    let newPeRecruitingYear = candidateProfile.resumeDetails.peRecruitingYear;
    if (JSON.stringify(newPeRecruitingYear) !== JSON.stringify(originalCandidateProfileState.resumeDetails.peRecruitingYear)) {
        updatedProfileDetails.resumeDetails.peRecruitingYear = cloneDeep(newPeRecruitingYear);
    }
    else {
        updatedProfileDetails.resumeDetails.peRecruitingYear = null;
    }

    let newJobOffer = candidateProfile.resumeDetails.jobOffer;
    if (JSON.stringify(newJobOffer) !== JSON.stringify(originalCandidateProfileState.resumeDetails.jobOffer)) {
        updatedProfileDetails.resumeDetails.jobOffer = cloneDeep(newJobOffer);
    }
    else {
        updatedProfileDetails.resumeDetails.jobOffer = null;
    }

    let newSuperDaySchedulingWorkflow = candidateProfile.scheduling.superDaySchedulingWorkflow;
    if (JSON.stringify(newSuperDaySchedulingWorkflow) !== JSON.stringify(originalCandidateProfileState.scheduling.superDaySchedulingWorkflow)) {
        updatedProfileDetails.scheduling.superDaySchedulingWorkflow = cloneDeep(newSuperDaySchedulingWorkflow);
    }
    else {
        updatedProfileDetails.scheduling.superDaySchedulingWorkflow = null;
    }

    let newCc1SchedulingWorkflow = candidateProfile.scheduling.cc1SchedulingWorkflow;
    if (JSON.stringify(newCc1SchedulingWorkflow) !== JSON.stringify(originalCandidateProfileState.scheduling.cc1SchedulingWorkflow)) {
        updatedProfileDetails.scheduling.cc1SchedulingWorkflow = cloneDeep(newCc1SchedulingWorkflow);
    }
    else {
        updatedProfileDetails.scheduling.cc1SchedulingWorkflow = null;
    }

    return updatedProfileDetails;
}

export const saveCandidateProfileAsync = createAsyncThunk(
    'candidateProfile/saveCandidateProfile',
    async (currentTab: string, thunkAPI) => {

        let response = null;
        let currentState: any = thunkAPI.getState();
        let profileUpdates = getOnlyUpdatedDetails(currentState.candidateProfile);
        let overviewUpdatesCommand: UpdateResumeProfileOverviewDto | undefined
            = _profileOverviewHelper.mapProfileOverviewUpdates(profileUpdates.profileOverview);

        if (currentTab === "resumeDetails") {
            let resumeDetailsUpdatesCommand: UpdateResumeDetailsDto | undefined
                = _resumeDetailsHelper.mapResumeDetailsUpdates(profileUpdates.resumeDetails)

            let updateCommandInterface: IUpdateResumeDetailsCommand = {
                candidateId: currentState.candidateProfile.candidateId,
                profileOverview: overviewUpdatesCommand,
                resumeDetails: resumeDetailsUpdatesCommand
            }

            let updateCommand: UpdateResumeDetailsCommand = new UpdateResumeDetailsCommand(updateCommandInterface);
            response = await (await api.postResumeDetails(updateCommand)).toJSON();
        }
        else if (currentTab === "notes") {
            let updatedSemiNotesDtos: UpdateCandidateNotesDto[] = []

            if (profileUpdates?.notes?.semiStructuredNotes) {
                profileUpdates?.notes?.semiStructuredNotes?.forEach((note) => {
                    var newSemiInterface: IUpdateCandidateNotesDto = {
                        id: note.candidateSemiStructuredNoteId,
                        noteHeaderId: note.semiStructuredNoteHeaderId,
                        note: note.note
                    }

                    updatedSemiNotesDtos.push(new UpdateCandidateNotesDto(newSemiInterface));
                });
            }

            let notesCommand: IUpdateCandidateNotesCommand = {
                candidateId: currentState.candidateProfile.candidateId,
                profileOverview: overviewUpdatesCommand,
                profileNote: profileUpdates?.notes?.profileNote,
                updateCandidateNotes: updatedSemiNotesDtos.length === 0 ? null : updatedSemiNotesDtos
            }

            let updateNotesCommand: UpdateCandidateNotesCommand = new UpdateCandidateNotesCommand(notesCommand);
            response = await (await api.updateCandidateNotes(updateNotesCommand)).toJSON();
        }
        else if (currentTab === "scheduling") {
            let schedulingUpdates: IScheduling = {
                superDaySchedulingWorkflow: profileUpdates.scheduling.superDaySchedulingWorkflow,
                jobOffer: profileUpdates.resumeDetails.jobOffer,
                cc1SchedulingWorkflow: profileUpdates.scheduling.cc1SchedulingWorkflow
            }

            let schedulingUpdateCommand: UpdateSchedulingDto | undefined
                = _schedulingHelper.mapSchedulingUpdates(schedulingUpdates);

            let updateCommandInterface: IUpdateSchedulingCommand = {
                candidateId: currentState.candidateProfile.candidateId,
                profileOverview: overviewUpdatesCommand,
                scheduling: schedulingUpdateCommand
            }

            let updateCommand: UpdateSchedulingCommand = new UpdateSchedulingCommand(updateCommandInterface);
            response = await (await api.postScheduling(updateCommand)).toJSON();
        }

        return response;
    }
);

export const closeCandidateProfile = (dispatch: Function, getState: Function) => {
    let currentState = getState();

    if (JSON.stringify(currentState.candidateProfile.profileOverview) !== JSON.stringify(originalCandidateProfileState.profileOverview)) {
        return true;
    }

    if (JSON.stringify(currentState.candidateProfile.resumeDetails) !== JSON.stringify(originalCandidateProfileState.resumeDetails)) {
        return true;
    }

    if (JSON.stringify(currentState.candidateProfile.notes) !== JSON.stringify(originalCandidateProfileState.notes)) {
        return true;
    }

    return false;
};

export const validateTabData = (currentTab: string) => {
    return (dispatch: Function, getState: Function): boolean => {
        const currentState = getState();

        if (currentTab === "resumeDetails" && JSON.stringify(currentState.candidateProfile.resumeDetails) !== JSON.stringify(originalCandidateProfileState.resumeDetails)) {
            return true;
        }
        else if (currentTab === "notes" && JSON.stringify(currentState.candidateProfile.notes) !== JSON.stringify(originalCandidateProfileState.notes)) {
            return true;
        }
        else if (currentTab === "scheduling" && JSON.stringify(currentState.candidateProfile.scheduling) !== JSON.stringify(originalCandidateProfileState.scheduling)) {
            return true;
        }

        return false;
    };
};

let originalCandidateProfileState: ICandidateProfileState;

export const candidateProfileSlice = createSlice({
    name: 'candidateProfile',
    initialState,
    reducers: {

        // OVERVIEW
        setOverviewIsTarget: (state, action) => {
            state.profileOverview.competitiveness.isTarget = action.payload
        },
        setOverviewIsDisqualified: (state, action) => {
            state.profileOverview.competitiveness.isDisqualified = action.payload
        },
        setOverviewPreSDPriority: (state, action) => {
            state.profileOverview.competitiveness.preSDPriority = action.payload
        },
        setOverviewSDPriority: (state, action) => {
            state.profileOverview.competitiveness.sdPriority = action.payload
        },
        setTargetCandidateCount: (state, action) => {
            state.profileOverview.competitiveness.targetCandidateCount = action.payload
        },
        setCandidateCount: (state, action) => {
            state.profileOverview.competitiveness.candidateCount = action.payload
        },
        setResumeScore: (state, action) => {
            state.profileOverview.competitiveness.resumeScore = action.payload
        },
        setResumeConfidence: (state, action) => {
            state.profileOverview.competitiveness.resumeConfidence = action.payload
        },
        setPoolRank: (state, action) => {
            state.profileOverview.competitiveness.poolRank = action.payload
        },
        // IDENTIFIERS
        setLinkedIn: (state, action) => {
            state.resumeDetails.identifier.linkedInUrl = action.payload;
        },
        setHenkel: (state, action) => {
            state.resumeDetails.identifier.henkelId = action.payload;
        },
        setOcean7: (state, action) => {
            state.resumeDetails.identifier.ocean7Id = action.payload;
        },

        // NAMES
        setCandidateName: (state, action) => {
            state.resumeDetails.contactInfo.candidateNames[action.payload[1]].fullName = action.payload[0];

            // if the data coming in is the same as the original data, the name is not dirty
            if (action.payload[0] === originalCandidateProfileState.resumeDetails.contactInfo?.candidateNames[action.payload[1]].fullName) {
                state.resumeDetails.contactInfo.candidateNames[action.payload[1]].isDirtyName = false;
            }
            else {
                state.resumeDetails.contactInfo.candidateNames[action.payload[1]].isDirtyName = true;
            }
        },
        setCandidateNamePrimary: (state, action) => {
            state.resumeDetails.contactInfo.candidateNames[state.resumeDetails.contactInfo.primaryNameIndex].isPrimary = false;
            state.resumeDetails.contactInfo.candidateNames[action.payload].isPrimary = true;
            state.resumeDetails.contactInfo.primaryNameIndex = action.payload;
        },
        incrementNumAltNames: (state) => {
            if (state.resumeDetails.contactInfo.altNamesCount < 5) {
                state.resumeDetails.contactInfo.altNamesCount += 1;
            }
        },

        // EMAILS
        setCandidateEmail: (state, action) => {
            state.resumeDetails.contactInfo.candidateEmails[action.payload[1]].email = action.payload[0];

            // if the data coming in is the same as the original data, the name is not dirty
            if (action.payload[0] === originalCandidateProfileState.resumeDetails.contactInfo?.candidateEmails[action.payload[1]].email) {
                state.resumeDetails.contactInfo.candidateEmails[action.payload[1]].isDirtyEmail = false;
            }
            else {
                state.resumeDetails.contactInfo.candidateEmails[action.payload[1]].isDirtyEmail = true;
            }
        },
        setCandidateEmailPrimary: (state, action) => {
            state.resumeDetails.contactInfo.candidateEmails[state.resumeDetails.contactInfo.primaryEmailIndex].isPrimary = false;
            state.resumeDetails.contactInfo.candidateEmails[action.payload].isPrimary = true;
            state.resumeDetails.contactInfo.primaryEmailIndex = action.payload;
        },
        incrementEmailsCount: (state) => {
            if (state.resumeDetails.contactInfo.emailsCount < 5) {
                state.resumeDetails.contactInfo.emailsCount += 1;
            }
        },

        // PHONES
        setCandidatePhone: (state, action) => {
            state.resumeDetails.contactInfo.candidatePhones[action.payload[1]].number = action.payload[0];

            // if the data coming in is the same as the original data, the name is not dirty
            if (action.payload[0] === originalCandidateProfileState.resumeDetails.contactInfo?.candidatePhones[action.payload[1]].number) {
                state.resumeDetails.contactInfo.candidatePhones[action.payload[1]].isDirtyPhone = false;
            }
            else {
                state.resumeDetails.contactInfo.candidatePhones[action.payload[1]].isDirtyPhone = true;
            }
        },
        setCandidatePhonePrimary: (state, action) => {
            state.resumeDetails.contactInfo.candidatePhones[state.resumeDetails.contactInfo.primaryPhoneIndex].isPrimary = false;
            state.resumeDetails.contactInfo.candidatePhones[action.payload].isPrimary = true;
            state.resumeDetails.contactInfo.primaryPhoneIndex = action.payload;
        },
        incrementPhonesCount: (state) => {
            if (state.resumeDetails.contactInfo.phonesCount < 5) {
                state.resumeDetails.contactInfo.phonesCount += 1;
            }
        },
        setCandidatePhoneSms: (state, action) => {
            state.resumeDetails.contactInfo.candidatePhones[action.payload].smsEnabled = !state.resumeDetails.contactInfo.candidatePhones[action.payload].smsEnabled;
            state.resumeDetails.contactInfo.candidatePhones[action.payload].isDirtySms = !state.resumeDetails.contactInfo.candidatePhones[action.payload].isDirtySms;
        },

        // LOCATIOM
        setCandidateInterviewPreference: (state, action) => {
            state.resumeDetails.location.interviewLocationPreferenceLookupId = action.payload;
        },
        setCandidateTimeZone: (state, action) => {
            state.resumeDetails.location.timeZoneLookupId = action.payload;
        },
        setCandidateHomeTownZip: (state, action) => {
            state.resumeDetails.location.homeTownZip = action.payload;
        },
        setCandidateHomeTown: (state, action) => {
            state.resumeDetails.location.homeTown = action.payload;
        },
        setCandidateHighSchool: (state, action) => {
            state.resumeDetails.location.highSchool = action.payload;
        },
        setCandidateWorkAuthorization: (state, action) => {
            state.resumeDetails.location.workAuthorizationLookupId = action.payload;
        },

        // CURRENT EMPLOYMENT
        setEmployerFirm: (state, action) => {
            state.resumeDetails.currentEmployment.employerFirmLookupId = action.payload;
        },
        setEmployerGroup: (state, action) => {
            state.resumeDetails.currentEmployment.employerGroupLookupId = action.payload;
        },

        // EDUCATION
        setCandidateSchool: (state, action) => {
            state.resumeDetails.education.schoolLookupId = action.payload;
            if (state.resumeDetails.education.undergradOtherSchool !== null) {
                if (action.payload !== state.lookupTables?.schools?.find((s) => s.name === "Other")?.id) {
                    state.resumeDetails.education.undergradOtherSchool = null;
                }
            }
        },
        setCandidateOtherSchool: (state, action) => {
            state.resumeDetails.education.undergradOtherSchool = action.payload;
        },
        setCandidateMajor: (state, action) => {
            state.resumeDetails.education.undergradMajor = action.payload;
        },
        setCandidateMajorCateogry: (state, action) => {
            state.resumeDetails.education.schoolMajorCategoryLookupId = action.payload;
        },
        setCandidateEducationYear: (state, action) => {
            state.resumeDetails.education.undergradYear = action.payload;
        },
        setCandidateEducationGPA: (state, action) => {
            state.resumeDetails.education.undergradGpa = action.payload;
        },
        setCandidateEducationGPAScale: (state, action) => {
            let newMaxGpa = action.payload;
            state.resumeDetails.education.undergradMaxGpa = newMaxGpa;
            if (state.resumeDetails.education?.undergradGpa !== undefined && state.resumeDetails.education.undergradGpa !== null) {
                if (newMaxGpa === 4 && state.resumeDetails.education?.undergradGpa > 4) {
                    state.resumeDetails.education.undergradGpa = 4
                }
            }
        },

        // STANDARDIZED TESTS
        setGmat: (state, action) => {
            state.resumeDetails.standardizedTests.testGmat = action.payload;
        },
        setSatMath: (state, action) => {
            state.resumeDetails.standardizedTests.testSatMath = action.payload;
        },
        setSatVerbal: (state, action) => {
            state.resumeDetails.standardizedTests.testSatVerbal = action.payload;
        },
        setSatTotal1600: (state, action) => {
            state.resumeDetails.standardizedTests.testSatTotal1600 = action.payload;
        },
        setSatTotal2400: (state, action) => {
            state.resumeDetails.standardizedTests.testSatTotal2400 = action.payload;
        },
        setAct: (state, action) => {
            state.resumeDetails.standardizedTests.testAct = action.payload;
        },

        // OTHER CONSIDERATIONS
        setLeadership: (state, action) => {
            state.resumeDetails.otherConsiderations.leadership = action.payload;
        },
        setAthletics: (state, action) => {
            state.resumeDetails.otherConsiderations.athletics = action.payload;
        },
        setGrit: (state, action) => {
            state.resumeDetails.otherConsiderations.grit = action.payload;
        },

        // VERTICAL INTERESTS
        setHealthcareInterest: (state, action) => {
            state.resumeDetails.verticalInterest.healthcareInterestLevelLookupId = action.payload;
        },
        setRetailAndConsumerInterest: (state, action) => {
            state.resumeDetails.verticalInterest.retailAndConsumerInterestLevelLookupId = action.payload;
        },
        setIndustrialsInterest: (state, action) => {
            state.resumeDetails.verticalInterest.industrialsInterestLevelLookupId = action.payload;
        },
        setTechInterest: (state, action) => {
            state.resumeDetails.verticalInterest.techInterestLevelLookupId = action.payload;
        },
        setFinancialServicesInterest: (state, action) => {
            state.resumeDetails.verticalInterest.financialServicesInterestLevelLookupId = action.payload;
        },

        // DIVERSITY
        setCandidateGender: (state, action) => {
            state.resumeDetails.diversity.genderLookupId = action.payload;
        },
        setCandidateGenderAssumed: (state) => {
            state.resumeDetails.diversity.genderAssumed = !state.resumeDetails.diversity.genderAssumed;
        },
        setCandidateRaceEthnicity: (state, action) => {
            state.resumeDetails.diversity.raceEthnicityLookupId = action.payload;
        },
        setCandidateRaceEthnicityAssumed: (state) => {
            state.resumeDetails.diversity.raceEthnicityAssumed = !state.resumeDetails.diversity.raceEthnicityAssumed;
        },
        setCandidateLGBTQ: (state, action) => {
            state.resumeDetails.diversity.lgbtq = action.payload;
        },
        setCandidateLBTQAssumed: (state) => {
            state.resumeDetails.diversity.lgbtqAssumed = !state.resumeDetails.diversity.lgbtqAssumed;
        },

        // AFFILIATION
        setGirlsWhoInvest: (state, action) => {
            state.resumeDetails.affiliation.girlsWhoInvest = action.payload ?? null;
        },
        setMlt: (state, action) => {
            state.resumeDetails.affiliation.mlt = action.payload ?? null;
        },
        setSeo: (state, action) => {
            state.resumeDetails.affiliation.seo = action.payload ?? null;
        },
        setAifp: (state, action) => {
            state.resumeDetails.affiliation.aifpLookupId = action.payload;
        },
        setSynergist: (state, action) => {
            state.resumeDetails.affiliation.synergist = action.payload;
        },
        setOutForUndergrad: (state, action) => {
            state.resumeDetails.affiliation.outForUndergrad = action.payload;
        },

        // PE RECRUITING YEAR
        setCandidatePreferredCycle: (state, action) => {
            state.resumeDetails.peRecruitingYear.preferredCycle = action.payload;
        },
        setCandidateAlternateCycle: (state, action) => {
            state.resumeDetails.peRecruitingYear.alternateCycle = action.payload;
        },
        setCandidateRecruitingYear: (state, action) => {
            state.resumeDetails.peRecruitingYear.recruitingYear = action.payload;
        },
        setCandidateRecruitOffCycle: (state, action) => {
            state.resumeDetails.peRecruitingYear.recruitOffCycle = action.payload ?? null;
        },

        // JOB OFFER DETAILS
        setCandidateOfferExtended: (state, action) => {
            state.resumeDetails.jobOffer.offerExtended = action.payload ?? null;
        },
        setCandidateOfferDateTime: (state, action) => {
            state.resumeDetails.jobOffer.offerDateTime = action.payload;
        },
        setCandidatePeEmployerLookup: (state, action) => {
            state.resumeDetails.jobOffer.peEmployerLookupId = action.payload;
            state.resumeDetails.jobOffer.incoming = action.payload ? true : false;
            state.resumeDetails.jobOffer.offTheMarket = action.payload ? true : false;
        },
        setCandidateOfferAccepted: (state) => {
            state.resumeDetails.jobOffer.offerAccepted = !state.resumeDetails.jobOffer.offerAccepted;
        },
        setSectorExperiencesLookup: (state, action) => {
            state.resumeDetails.jobOffer.verticalTypeLookupId = action.payload
        },
        setCandidateIncoming: (state) => {
            state.resumeDetails.jobOffer.incoming = !state.resumeDetails.jobOffer.incoming;
        },
        setCandidateOffTheMarket: (state) => {
            state.resumeDetails.jobOffer.offTheMarket = !state.resumeDetails.jobOffer.offTheMarket;
        },

        // NOTES
        setCandidateProfileNote: (state, action) => {
            state.notes.profileNote = action.payload;
        },
        setCandidateSemiStructuredNotes: (state, action) => {
            let noteHeaderId = action.payload[1];
            let index = state.notes.semiStructuredNotes?.findIndex(n => n.semiStructuredNoteHeaderId === noteHeaderId);
            if (index !== undefined) {
                if (state?.notes?.semiStructuredNotes)
                    state.notes.semiStructuredNotes[index].note = action.payload[0];
            }
        },

        //SCHEDULING
        setCandidateSuperDaySchedulingEligibility: (state, action) => {
            state.scheduling.superDaySchedulingWorkflow.eligibility = action.payload ?? null;
        },
        setCandidateSuperDaySchedulingStatusLookupId: (state, action) => {
            state.scheduling.superDaySchedulingWorkflow.schedulingWorkflowStatusLookupId = action.payload ?? null;
        },
        setCandidateSuperDaySchedulingArrivalDateTime: (state, action) => {
            state.scheduling.superDaySchedulingWorkflow.arrivalDateTime = action.payload ?? null;
        },
        setCandidateCc1SchedulingEligibility: (state, action) => {
            state.scheduling.cc1SchedulingWorkflow.eligibility = action.payload ?? null;
        },
        setCandidateCc1SchedulingStatusLookupId: (state, action) => {
            state.scheduling.cc1SchedulingWorkflow.schedulingWorkflowStatusLookupId = action.payload ?? null;
        },
        setCandidateCc1SchedulingArrivalDateTime: (state, action) => {
            state.scheduling.cc1SchedulingWorkflow.arrivalDateTime = action.payload ?? null;
        },
        setCandidateCc1InterviewerId: (state, action) => {
            state.scheduling.cc1SchedulingWorkflow.interviewerId = action.payload ?? null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCandidateProfileAsync.pending, (state) => {
                state.status = 'loading';
                Object.assign(state, initialState);
            })
            .addCase(getCandidateProfileAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                if (action.payload !== null) {
                    buildStateFromGetOrPost(state, action);
                }
                originalCandidateProfileState = cloneDeep(state);
            })
            .addCase(getCandidateProfileAsync.rejected, (state, action) => {
                state.status = 'idle';
            })
            .addCase(saveCandidateProfileAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(saveCandidateProfileAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                buildStateFromGetOrPost(state, action);
                originalCandidateProfileState = cloneDeep(state);
            })
            .addCase(saveCandidateProfileAsync.rejected, (state, action) => {
                state.status = 'idle';
            })
            .addCase(getResumeDetailLookups.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getResumeDetailLookups.fulfilled, (state, action) => {
                state.status = 'idle';
                state.lookupTables = action.payload
            })
            .addCase(getResumeDetailLookups.rejected, (state, action) => {
                state.status = 'idle';
            })
            .addCase(getScoringAndInterviewsLookups.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getScoringAndInterviewsLookups.fulfilled, (state, action) => {
                state.status = 'idle';
                state.scoringLookupTables = action.payload
            })
            .addCase(getScoringAndInterviewsLookups.rejected, (state, action) => {
                state.status = 'idle';
            })
            .addCase(getSchedulingLookups.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getSchedulingLookups.fulfilled, (state, action) => {
                state.status = 'idle';
                state.schedulingLookupTables = action.payload
            })
            .addCase(getSchedulingLookups.rejected, (state, action) => {
                state.status = 'idle';
            })
    },
});

export const {
    // OVERVIEW
    setOverviewIsTarget, setOverviewIsDisqualified, setOverviewPreSDPriority, setOverviewSDPriority,

    // IDENTIFIERS
    setLinkedIn, setHenkel, setOcean7,

    // NAMES
    incrementNumAltNames, setCandidateName, setCandidateNamePrimary,

    // EMAILS
    incrementEmailsCount, setCandidateEmail, setCandidateEmailPrimary,

    // PHONES
    incrementPhonesCount, setCandidatePhone, setCandidatePhonePrimary, setCandidatePhoneSms,

    // LOCATION
    setCandidateInterviewPreference, setCandidateTimeZone, setCandidateHomeTownZip, setCandidateHomeTown,
    setCandidateHighSchool, setCandidateWorkAuthorization,

    // CURRENT EMPLOYMENT
    setEmployerFirm, setEmployerGroup,

    // EDUCATION
    setCandidateSchool, setCandidateOtherSchool, setCandidateMajor, setCandidateMajorCateogry,
    setCandidateEducationYear, setCandidateEducationGPA, setCandidateEducationGPAScale,

    // STANDARDIZED TESTS
    setGmat, setSatMath, setSatVerbal, setSatTotal1600, setSatTotal2400, setAct,

    // OTHER CONSIDERATIONS
    setLeadership, setAthletics, setGrit,

    // VERTICAL INTERESTS
    setHealthcareInterest, setRetailAndConsumerInterest, setIndustrialsInterest, setTechInterest, setFinancialServicesInterest,

    // DIVERSITY
    setCandidateGender, setCandidateGenderAssumed, setCandidateRaceEthnicity, setCandidateRaceEthnicityAssumed,
    setCandidateLGBTQ, setCandidateLBTQAssumed,

    // AFFILIATION
    setGirlsWhoInvest, setMlt, setSeo, setAifp, setSynergist, setOutForUndergrad,

    // PE RECRUITING YEAR
    setCandidateAlternateCycle, setCandidatePreferredCycle, setCandidateRecruitingYear, setCandidateRecruitOffCycle,

    // JOB OFFER DETAILS
    setCandidateOfferExtended, setCandidateOfferDateTime, setCandidatePeEmployerLookup, setCandidateOfferAccepted,
    setSectorExperiencesLookup, setCandidateIncoming, setCandidateOffTheMarket,

    // NOTES
    setCandidateProfileNote, setCandidateSemiStructuredNotes,

    //SCHEDULING
    setCandidateSuperDaySchedulingEligibility, setCandidateSuperDaySchedulingStatusLookupId, setCandidateSuperDaySchedulingArrivalDateTime,
    setCandidateCc1SchedulingEligibility, setCandidateCc1SchedulingStatusLookupId, setCandidateCc1SchedulingArrivalDateTime, setCandidateCc1InterviewerId

} = candidateProfileSlice.actions;

export default candidateProfileSlice.reducer;