import React from 'react';
import { Container } from 'reactstrap';
import { AppBar, AppBarSection, AppBarSpacer } from "@progress/kendo-react-layout";
import CDRLogo from "./CDRLogo";
import NavMenu from "./NavMenu";
import Breadcrumb from "./Breadcrumb";
import UserLogin from "../UserLogin/UserLogin";
import { isSafari } from 'react-device-detect';
const Header = () => {
    return (
        <Container fluid={true} className="header">
            <AppBar className="header-first" position="top" >
                <AppBarSection>
                    {!isSafari && <Breadcrumb />}
                </AppBarSection>

                <AppBarSpacer />

                <AppBarSection  >
                    <CDRLogo />
                </AppBarSection>
            </AppBar>
            <AppBar className="nav-bar">
                <AppBarSection>
                    <NavMenu />
                </AppBarSection>

                <AppBarSpacer />

                <AppBarSection>
                    <UserLogin />
                </AppBarSection>
            </AppBar>
        </Container>
    )
}

export default Header;