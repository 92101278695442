import { Col, ColProps, Container, ContainerProps, Row, RowProps } from "reactstrap"

interface ICenterProps {
  children: JSX.Element,
  containerProps?: ContainerProps,
  rowProps?: RowProps,
  columnProps?: ColProps,
  divProps?: React.CSSProperties
}
 
export const Center = (props: ICenterProps) => (
  <Container {...props.containerProps}>
    <Row {...props.rowProps}>
      <Col
        {...props.columnProps}
        xs={{
          offset: 3,
          size: 6
        }}
      >
        <div style={{textAlign: 'center', ...props.divProps}}>{props.children}</div>
      </Col>
    </Row>
  </Container>
);