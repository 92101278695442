import React from 'react';
import Header from './Header'
import { Container } from 'reactstrap';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Footer from './Footer';
import { useAppSelector } from "../../app/hook";
import ErrorAlert from './ErrorAlert';

export interface ILayoutProps {
    children: React.ReactNode
}

const Layout = (props: ILayoutProps) => {

    const currentUser = useAppSelector((state) => state.currentUser);

    return (
        <>
            <Header />
            <ErrorAlert />
            {currentUser.accessToken &&
                <AuthenticatedTemplate>
                    <Container fluid={true}>
                        <div className="main-content">
                            {props.children}
                        </div>
                    </Container>
                </AuthenticatedTemplate>
            }
            <UnauthenticatedTemplate />
            <div className="footer-offset"></div>
            <Footer />
        </>
    );
}

export default Layout