import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import {
    Client, IInterviewFeedbackDto, IUpdateInterviewFeedbackCommand
    , ICandidatesLookupVm, InterviewersVm, InterviewFeedbackDto, ConnectionLevelVm, IncomingCandidatesVm, UpdateInterviewFeedbackCommand
} from '../../Api/client-api';

const api = new Client("", axios);

const initialInterviewFeedback: IInterviewFeedbackDto = {
    id: null,
    candidateId: null,
    interviewerId: null,
    interviewTypeName: null,
    interviewerName: null,
    feedbackTime: null,
    score: null,
    feedback: null
}

export interface ICandidateFeedbackState {
    status: string,
    interviewFeedback: IInterviewFeedbackDto,
    candidates: ICandidatesLookupVm | undefined,
    interviewers: InterviewersVm | undefined,
    connectionLevels: ConnectionLevelVm | undefined,
    incomingCandidates: IncomingCandidatesVm | undefined
};

const initialState: ICandidateFeedbackState = {
    status: 'idle',
    interviewFeedback: initialInterviewFeedback,
    candidates: undefined,
    interviewers: undefined,
    connectionLevels: undefined,
    incomingCandidates: undefined
};

export const getAllCandidates = createAsyncThunk(
    'interviewFeedback/fetchAllCandidates',
    async () => {
        let response = await (await api.getAllCandidates()).toJSON();

        for (const element of response.candidatesLookUp) {
            element.fullNameEmail = element.fullName + (element.email !== null ? "; " + element.email : "");
        }
        return response;
    }
)

export const getInterviewers = createAsyncThunk(
    'interviewFeedback/fetchInterviewers',
    async () => {
        let response = await (await api.getInterviewers()).toJSON();

        return response;
    }
)

export const getConnectionLevels = createAsyncThunk(
    'interviewFeedback/fetchConnectionLevels',
    async () => {
        let response = await (await api.getConnectionLevels()).toJSON();

        return response;
    }
)

export const getIncomingCandidates = createAsyncThunk(
    'interviewFeedback/fetchIncomingCandidates',
    async () => {
        let response = await (await api.getIncomingCandidates()).toJSON();

        return response;
    }
)

export const getInterviewFeedbackAsync = createAsyncThunk(
    'interviewFeedback/fetchInterviewFeedback',
    async (interviewFeedback: IInterviewFeedbackDto) => {
        let response = null;
        let candidateId = interviewFeedback.candidateId || undefined;
        let interviewTypeName = interviewFeedback.interviewTypeName || undefined;

        if (candidateId !== undefined) {
            response = await api.getInterviewFeedback(candidateId, interviewTypeName);
        }
        // the value we return becomes the 'fulfilled' action payload
        return response;
    }
);

export const saveInterviewFeedbackAsync = createAsyncThunk(
    'interviewFeedback//saveInterviewFeedback/',
    async (interviewFeedback: IInterviewFeedbackDto) => {
        let response = null;
        let interviewFeedbackDto = new InterviewFeedbackDto(interviewFeedback);

        let updateCommandInterface: IUpdateInterviewFeedbackCommand = {
            interviewFeedback: interviewFeedbackDto
        }

        let updateCommand: UpdateInterviewFeedbackCommand = new UpdateInterviewFeedbackCommand(updateCommandInterface);
        response = await (await api.postInterviewFeedback(updateCommand)).toJSON();

        return response;
    }
);

function buildStateFromGet(state: any, action: any): void {
    state.interviewFeedback.id = action.payload?.id || null;
    state.interviewFeedback.candidateId = action.payload?.candidateId || null;
    state.interviewFeedback.interviewerId = action.payload?.interviewerId || null;
    state.interviewFeedback.interviewTypeName = action.payload?.interviewTypeName || null;
    state.interviewFeedback.interviewerName = action.payload?.interviewerName || null;
    state.interviewFeedback.score = action.payload?.score || null;
    state.interviewFeedback.feedback = action.payload?.feedback || null;
}

export const candidateFeedbackSlice = createSlice({
    name: 'candidateFeedback',
    initialState,
    reducers: {
        setCandidateId: (state, action) => {
            state.interviewFeedback.candidateId = action.payload
        },
        setInterviewId: (state, action) => {
            state.interviewFeedback.id = action.payload
        },
        setInterviewerId: (state, action) => {
            state.interviewFeedback.interviewerId = action.payload
        },
        setInterviewTypeName: (state, action) => {
            state.interviewFeedback.interviewTypeName = action.payload
        },
        setInterviewerName: (state, action) => {
            state.interviewFeedback.interviewerName = action.payload
        },
        setScore: (state, action) => {
            state.interviewFeedback.score = action.payload
        },
        setFeedback: (state, action) => {
            state.interviewFeedback.feedback = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getInterviewFeedbackAsync.pending, (state) => {
                state.status = 'loading';
                Object.assign(state, initialState);
            })
            .addCase(getInterviewFeedbackAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload !== null) {
                    buildStateFromGet(state, action);
                }
            })
            .addCase(getInterviewFeedbackAsync.rejected, (state, action) => {
                state.status = 'idle';
            })
            .addCase(saveInterviewFeedbackAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(saveInterviewFeedbackAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.interviewFeedback = action.payload;
            })
            .addCase(saveInterviewFeedbackAsync.rejected, (state, action) => {
                state.status = 'idle';
            })
            .addCase(getAllCandidates.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAllCandidates.fulfilled, (state, action) => {
                state.status = 'idle';
                state.candidates = action.payload
            })
            .addCase(getAllCandidates.rejected, (state, action) => {
                state.status = 'idle';
            })
            .addCase(getInterviewers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getInterviewers.fulfilled, (state, action) => {
                state.status = 'idle';
                state.interviewers = action.payload
            })
            .addCase(getInterviewers.rejected, (state, action) => {
                state.status = 'idle';
            })
            .addCase(getConnectionLevels.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getConnectionLevels.fulfilled, (state, action) => {
                state.status = 'idle';
                state.connectionLevels = action.payload
            })
            .addCase(getConnectionLevels.rejected, (state, action) => {
                state.status = 'idle';
            })
            .addCase(getIncomingCandidates.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getIncomingCandidates.fulfilled, (state, action) => {
                state.status = 'idle';
                state.incomingCandidates = action.payload
            })
            .addCase(getIncomingCandidates.rejected, (state, action) => {
                state.status = 'idle';
            })
    },
});

export const {
    setCandidateId, setInterviewerId, setInterviewId, setInterviewTypeName, setScore, setFeedback
} = candidateFeedbackSlice.actions;

export default candidateFeedbackSlice.reducer;