import React from "react";

const CDRLogo = (): JSX.Element => {

    return (
        <React.Fragment>
            <div className="header-logo">
                <img
                    src="/images/cdr-logo.png"
                    alt="CDR Logo"
                />
            </div>
        </React.Fragment>
    );
};

export default CDRLogo;

