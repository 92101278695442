import React, { useState, useEffect } from "react";
import { Redirect, Route } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import IRoute from "../../Interfaces/IRoute";

/*
    This code along with the Router and the RouteConfig came from the following article:
    https://medium.com/@michael.kutateladze/react-router-with-a-single-config-file-61777f306b4f
    We may need to tweak to suit.
    TODO: Need to add a 404 not found route.
*/

const RouteWithSubRoutes = (route: IRoute) => {
    /** Authenticated flag */
    // const authenticated: boolean = useIsAuthenticated();

    const { instance } = useMsal();
    const [isAuthorized, setIsAuthorized] = useState(false);

    const onLoad = async () => {
        const currentAccount = instance.getActiveAccount();

        if (currentAccount?.idTokenClaims) {
            var idTokenClaims: any = currentAccount?.idTokenClaims;

            let intersection = route.roles
                .filter((role: any) => idTokenClaims?.roles.includes(role));

            if (intersection.length > 0) {
                setIsAuthorized(true);
            }
        }
    }

    useEffect(() => {
        onLoad();
    });

    return (
        <>
            {
                isAuthorized
                    ?
                    <Route
                        path={route.path}
                        render={(props) =>
                            route.redirect ? <Redirect to={route.redirect} /> :
                                route.private ? (
                                    route.component &&
                                    <route.component {...props} routes={route.routes} />
                                ) : route.component && <route.component {...props} routes={route.routes} />
                        }
                    />
                    :
                    <div className="data-area-div">
                        <h3>You are unauthorized to view this content.</h3>
                    </div>
            }
        </>
    );
};

export default RouteWithSubRoutes;