import { Store } from "redux";
import {
    AuthError,
    AuthenticationResult,
    PublicClientApplication,
    SilentRequest
} from '@azure/msal-browser';
import { msalConfig } from "./authConfig";
import { emptyMsalAccountInfo } from '../store/slices/currentUserSlice';
import ICurrentUser from "../Interfaces/ICurrentUser";
import { updateCurrentUser } from "../store/slices/currentUserSlice";

/**
 * Encapsulates the authentication methods into one class.
 */
export default class AuthenticationService {

    private msalBrowser: PublicClientApplication;
    private scope = process.env.REACT_APP_AZAD_SCOPE!;

    private silentRequestForCandidateManagerApi: SilentRequest = {
        scopes: [this.scope], // For the token that grants access to the API.
        account: { ...emptyMsalAccountInfo },
        forceRefresh: false
    };

    constructor() {
        this.msalBrowser = new PublicClientApplication(msalConfig);
    }

    /**
     * This call is for when the user needs to update and expired authentication token for accessing the API.
     *
     * @returns Either the updated token or an empty string unable to get a new token.
     */
    getCandidateManagerApiTokenSilent = async (store: Store): Promise<string> => {
        const { currentUser } = store.getState();
        const silentRequest = { ...this.silentRequestForCandidateManagerApi, account: { ...currentUser.account } };
        let token = '';

        await this.msalBrowser.acquireTokenSilent(silentRequest).then(async (graphResult: AuthenticationResult): Promise<void> => {

            const userUpdated: ICurrentUser = {
                ...currentUser,
                accessToken: graphResult.accessToken,
                accessTokenExpiresOn: graphResult.expiresOn?.toString()
            };

            store.dispatch(updateCurrentUser({ ...userUpdated }));
            token = graphResult.accessToken;

        }).catch((error: AuthError) => {
            console.log(error);
        });

        return token;
    }
}
