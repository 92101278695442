/* reference for cookie methods: https://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript */

export const setCookie = (name: string, value: string, days: number | undefined | null) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export const getCookie = (name: string) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export const eraseCookie = (name: string) => {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export const getShowOldCandidates = (): boolean => {
    const showOldCandidates = getCookie("ShowOldCandidates");
    return showOldCandidates === "true";
}

export const setShowOldCandidates = (show: boolean) => {
    setCookie("ShowOldCandidates", show ? "true" : "false", 180);
}

export const getShowOldEvents = (): boolean => {
    const showOldEvents = getCookie("ShowOldEvents");
    return showOldEvents === "true";
}

export const setShowOldEvents = (show: boolean) => {
    setCookie("ShowOldEvents", show ? "true" : "false", 180);
}
