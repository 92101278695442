export const RESUMENAME = 0;
export const ALTNAME1 = 1;
export const ALTNAME2 = 2;
export const ALTNAME3 = 3;
export const ALTNAME4 = 4;
export const EMAIL1 = 0;
export const EMAIL2 = 1;
export const EMAIL3 = 2;
export const EMAIL4 = 3;
export const EMAIL5 = 4;
export const PHONE1 = 0;
export const PHONE2 = 1;
export const PHONE3 = 2;
export const PHONE4 = 3;
export const PHONE5 = 4;