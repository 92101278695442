import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import {
    Client, IEmployeeProfileVm, IUpdateEmployeeProfileDto, UpdateEmployeeProfileDto,
    IUpdateEmployeeProfileCommand, UpdateEmployeeProfileCommand,
    IEmployeeProfileDetailsLookupsVm,
    IEmployeeInterviewScoresDto,
} from '../../Api/client-api';

const api = new Client("", axios);

const initialEmployeeProfile: IEmployeeProfileVm = {
    employeeId: undefined,
    isActive: null,
    isInterviewing: null,
    lastUpdated: null,
    fullName: null,
    alternateFullName: null,
    email: null,
    homeTownZip: null,
    homeTown: null,
    highSchool: null,
    preEmployerFirmLookupId: null,
    employerFirmLookupName: null,
    employeeLevelLookupId: null,
    employeeLevelLookupName: null,
    preEmployerGroupLookupId: null,
    schoolLookupId: null,
    schoolLookupName: null,
    undergradMajor: null,
    verticalTypeLookupId: null,
    genderLookupId: null,
    genderAssumed: null,
    raceEthnicityLookupId: null,
    raceEthnicityAssumed: null,
    lgbtq: null,
    lgbtqAssumed: null,
    girlsWhoInvest: null,
    mlt: null,
    seo: null,
    aifpLookupId: null,
    synergist: null,
    outForUndergrad: null

};

const initialEmployeeInterviewScores: IEmployeeInterviewScoresDto = {
    biScore12MonthTrailing: 0,
    biCount12MonthTrailing: 0,
    biScore12MonthTrailingAverage: 0,
    biScoreTotal: 0,
    biCountTotal: 0,
    biScoreTotalAverage: 0,
    informalLboScore12MonthTrailing: 0,
    informalLboCount12MonthTrailing: 0,
    informalLboScore12MonthTrailingAverage: 0,
    informalLboScoreTotal: 0,
    informalLboCountTotal: 0,
    informalLboScoreTotalAverage: 0,
    lboScore12MonthTrailing: 0,
    lboCount12MonthTrailing: 0,
    lboScore12MonthTrailingAverage: 0,
    lboScoreTotal: 0,
    lboCountTotal: 0,
    lboScoreTotalAverage: 0,
    caseScore12MonthTrailing: 0,
    caseCount12MonthTrailing: 0,
    caseScore12MonthTrailingAverage: 0,
    caseScoreTotal: 0,
    caseCountTotal: 0,
    caseScoreTotalAverage: 0,
    bonusScore12MonthTrailing: 0,
    bonusCount12MonthTrailing: 0,
    bonusScore12MonthTrailingAverage: 0,
    bonusScoreTotal: 0,
    bonusCountTotal: 0,
    bonusScoreTotalAverage: 0,
}

export interface IEmployeeProfileState {
    status: string,
    profile: IEmployeeProfileVm,
    profileDetailsLookups: IEmployeeProfileDetailsLookupsVm | undefined,
    interviewScores: IEmployeeInterviewScoresDto | undefined,
};

const initialState: IEmployeeProfileState = {
    status: 'idle',
    profile: initialEmployeeProfile,
    profileDetailsLookups: undefined,
    interviewScores: initialEmployeeInterviewScores,
};

export const getEmployeeProfileAsync = createAsyncThunk(
    'employeeProfile/fetchEmployeeProfile',
    async (id: number | undefined) => {
        let response = null;

        if (id !== undefined) {
            response = await (await api.getEmployee(id)).toJSON();
        }
        // the value we return becomes the 'fulfilled' action payload
        return response;
    }
);

export const getEmployeeProfileDetailLookups = createAsyncThunk(
    'employeeProfile/fetchEmployeeProfileDetailLookups',
    async () => {
        let response = await (await api.getEmployeeProfileDetailsLookups()).toJSON();

        return response;
    }
);

export const saveEmployeeProfileAsync = createAsyncThunk(
    'employeeProfile/saveEmployeeProfile',
    async (currentTab: string, thunkAPI) => {

        let response = null;
        let currentState: any = thunkAPI.getState();
        let iUpdateEmployeeProfileDto: IUpdateEmployeeProfileDto = {
            isActive: currentState.employeeProfile.profile.isActive,
            isInterviewing: currentState.employeeProfile.profile.isInterviewing,
            homeTownZip: currentState.employeeProfile.profile.homeTownZip,
            homeTown: currentState.employeeProfile.profile.homeTown,
            highSchool: currentState.employeeProfile.profile.highSchool,
            employeeLevelLookupId: currentState.employeeProfile.profile.employeeLevelLookupId,
            employerFirmLookupId: currentState.employeeProfile.profile.preEmployerFirmLookupId,
            employerGroupLookupId: currentState.employeeProfile.profile.preEmployerGroupLookupId,
            schoolLookupId: currentState.employeeProfile.profile.schoolLookupId,
            undergradMajor: currentState.employeeProfile.profile.undergradMajor,
            verticalTypeLookupId: currentState.employeeProfile.profile.verticalTypeLookupId,
            genderLookupId: currentState.employeeProfile.profile.genderLookupId,
            genderAssumed: currentState.employeeProfile.profile.genderAssumed,
            raceEthnicityLookupId: currentState.employeeProfile.profile.raceEthnicityLookupId,
            raceEthnicityAssumed: currentState.employeeProfile.profile.raceEthnicityAssumed,
            lgbtq: currentState.employeeProfile.profile.lgbtq,
            lgbtqAssumed: currentState.employeeProfile.profile.lgbtqAssumed,
            girlsWhoInvest: currentState.employeeProfile.profile.girlsWhoInvest,
            mlt: currentState.employeeProfile.profile.mlt,
            seo: currentState.employeeProfile.profile.seo,
            aifpLookupId: currentState.employeeProfile.profile.aifpLookupId,
            synergist: currentState.employeeProfile.profile.synergist,
            outForUndergrad: currentState.employeeProfile.profile.outForUndergrad
        }
        let updateEmployeeProfileDto = new UpdateEmployeeProfileDto(iUpdateEmployeeProfileDto);

        let iUpdateEmployeeProfileCommand: IUpdateEmployeeProfileCommand = {
            employeeId: currentState.employeeProfile.profile.employeeId,
            alternateFullName: currentState.employeeProfile.profile.alternateFullName,
            employeeProfile: updateEmployeeProfileDto,
        }

        let updateCommand: UpdateEmployeeProfileCommand = new UpdateEmployeeProfileCommand(iUpdateEmployeeProfileCommand);
        response = await (await api.updateEmployeeProfile(updateCommand)).toJSON();

        return response;
    }
);

export const getEmployeeInterviewScoresAsync = createAsyncThunk(
    'employeeProfile/fetchEmployeeInterviewScores',
    async (id: number | undefined) => {
        let response = null;

        if (id !== undefined) {
            response = await (await api.getEmployeeInterviewScores(id)).toJSON();
        }
        // the value we return becomes the 'fulfilled' action payload
        return response;
    }
);

function buildStateFromGetOrPost(state: any, action: any): void {
    state.profile.employeeId = action.payload.employeeId;
    state.profile.isActive = action.payload.isActive;
    state.profile.isInterviewing = action.payload.isInterviewing;
    state.profile.lastUpdated = action.payload.lastUpdated ? new Date(action.payload.lastUpdated).toLocaleString() : null;

    state.profile.fullName = action.payload.fullName;
    state.profile.alternateFullName = action.payload.alternateFullName;
    state.profile.email = action.payload.email;
    state.profile.homeTownZip = action.payload.homeTownZip;
    state.profile.homeTown = action.payload.homeTown;
    state.profile.highSchool = action.payload.highSchool;
    state.profile.preEmployerFirmLookupId = action.payload.preEmployerFirmLookupId;
    state.profile.employeeLevelLookupId = action.payload.employeeLevelLookupId;
    state.profile.employeeLevelLookupName = action.payload.employeeLevelLookupName
    state.profile.employerFirmLookupName = action.payload.employerFirmLookupName;
    state.profile.preEmployerGroupLookupId = action.payload.preEmployerGroupLookupId;
    state.profile.schoolLookupId = action.payload.schoolLookupId;
    state.profile.schoolLookupName = action.payload.schoolLookupName;
    state.profile.undergradMajor = action.payload.undergradMajor;
    state.profile.verticalTypeLookupId = action.payload.verticalTypeLookupId;
    state.profile.genderLookupId = action.payload.genderLookupId;
    state.profile.genderAssumed = action.payload.genderAssumed;
    state.profile.raceEthnicityLookupId = action.payload.raceEthnicityLookupId;
    state.profile.raceEthnicityAssumed = action.payload.raceEthnicityAssumed;
    state.profile.lgbtq = action.payload.lgbtq;
    state.profile.lgbtqAssumed = action.payload.lgbtqAssumed;
    state.profile.girlsWhoInvest = action.payload.girlsWhoInvest;
    state.profile.mlt = action.payload.mlt;
    state.profile.seo = action.payload.seo;
    state.profile.aifpLookupId = action.payload.aifpLookupId;
    state.profile.synergist = action.payload.synergist;
    state.profile.outForUndergrad = action.payload.outForUndergrad;
}

export const employeeProfileSlice = createSlice({
    name: 'employeeProfile',
    initialState,
    reducers: {
        setIsActive: (state, action) => {
            state.profile.isActive = action.payload;
        },
        setIsInterviewing: (state, action) => {
            state.profile.isInterviewing = action.payload;
        },
        setHomeTownZip: (state, action) => {
            state.profile.homeTownZip = action.payload;
        },
        setHomeTown: (state, action) => {
            state.profile.homeTown = action.payload;
        },
        setHighSchool: (state, action) => {
            state.profile.highSchool = action.payload;
        },
        setPreEmployerFirmLookupId: (state, action) => {
            state.profile.preEmployerFirmLookupId = action.payload;
        },
        setEmployeeLevelLookupId: (state, action) => {
            state.profile.employeeLevelLookupId = action.payload;
        },
        setPreEmployerGroupLookupId: (state, action) => {
            state.profile.preEmployerGroupLookupId = action.payload;
        },
        setSchoolLookupId: (state, action) => {
            state.profile.schoolLookupId = action.payload;
        },
        setUndergradMajor: (state, action) => {
            state.profile.undergradMajor = action.payload;
        },
        setVerticalTypeLookupId: (state, action) => {
            state.profile.verticalTypeLookupId = action.payload;
        },
        setGenderLookupId: (state, action) => {
            state.profile.genderLookupId = action.payload;
        },
        setGenderAssumed: (state) => {
            state.profile.genderAssumed = !state.profile.genderAssumed;
        },
        setRaceEthnicityLookupId: (state, action) => {
            state.profile.raceEthnicityLookupId = action.payload;
        },
        setRaceEthnicityAssumed: (state) => {
            state.profile.raceEthnicityAssumed = !state.profile.raceEthnicityAssumed;
        },
        setLgbtq: (state, action) => {
            state.profile.lgbtq = action.payload;
        },
        setLgbtqAssumed: (state) => {
            state.profile.lgbtqAssumed = !state.profile.lgbtqAssumed;
        },
        setGirlsWhoInvest: (state, action) => {
            state.profile.girlsWhoInvest = action.payload;
        },
        setMlt: (state, action) => {
            state.profile.mlt = action.payload;
        },
        setSeo: (state, action) => {
            state.profile.seo = action.payload;
        },
        setAifpLookupId: (state, action) => {
            state.profile.aifpLookupId = action.payload;
        },
        setSynergist: (state, action) => {
            state.profile.synergist = action.payload;
        },
        setOutForUndergrad: (state, action) => {
            state.profile.outForUndergrad = action.payload;
        },
        setNamePrimary: (state, action) => {
            state.profile.fullName = action.payload;
        },
        setAlternateName: (state, action) => {
            state.profile.alternateFullName = action.payload[0];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEmployeeProfileAsync.pending, (state) => {
                state.status = 'loading';
                Object.assign(state, initialState);
            })
            .addCase(getEmployeeProfileAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                if (action.payload !== null) {
                    buildStateFromGetOrPost(state, action);
                }
            })
            .addCase(getEmployeeProfileAsync.rejected, (state, action) => {
                state.status = 'idle';
            })
            .addCase(saveEmployeeProfileAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(saveEmployeeProfileAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                buildStateFromGetOrPost(state, action);
            })
            .addCase(saveEmployeeProfileAsync.rejected, (state, action) => {
                state.status = 'idle';
            })
            .addCase(getEmployeeProfileDetailLookups.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getEmployeeProfileDetailLookups.fulfilled, (state, action) => {
                state.status = 'idle';
                state.profileDetailsLookups = action.payload
            })
            .addCase(getEmployeeProfileDetailLookups.rejected, (state, action) => {
                state.status = 'idle';
            })
            .addCase(getEmployeeInterviewScoresAsync.pending, (state) => {
                state.status = 'loading';
                Object.assign(state, initialState);
            })
            .addCase(getEmployeeInterviewScoresAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.interviewScores = action.payload;
            })
            .addCase(getEmployeeInterviewScoresAsync.rejected, (state, action) => {
                state.status = 'idle';
            })
    },
});

export const {
    setIsActive, setIsInterviewing,
    setHomeTownZip, setHomeTown,
    setHighSchool, setPreEmployerFirmLookupId, setEmployeeLevelLookupId,
    setPreEmployerGroupLookupId, setSchoolLookupId,
    setUndergradMajor, setVerticalTypeLookupId,
    setGenderLookupId, setGenderAssumed,
    setRaceEthnicityLookupId, setRaceEthnicityAssumed,
    setLgbtq, setLgbtqAssumed,
    setGirlsWhoInvest, setMlt,
    setSeo, setAifpLookupId, setSynergist,
    setOutForUndergrad,
    setNamePrimary, setAlternateName
} = employeeProfileSlice.actions;

export default employeeProfileSlice.reducer;