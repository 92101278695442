import { CandidateCompetitivenessDto, IUpdateResumeProfileOverviewDto, UpdateResumeProfileOverviewDto } from "../../Api/client-api";
import { IProfileOverview } from "../../Interfaces/IProfileOverview";

export function mapProfileOverviewUpdates(overviewUpdates: IProfileOverview): UpdateResumeProfileOverviewDto {
    let newCompetitiveness: CandidateCompetitivenessDto | undefined = undefined;

    if (overviewUpdates?.competitiveness) {
        newCompetitiveness = new CandidateCompetitivenessDto(overviewUpdates.competitiveness);
    }

    let updatedProfileOverview: IUpdateResumeProfileOverviewDto = {
        competitiveness: newCompetitiveness,
    }

    let profileOverviewDto = new UpdateResumeProfileOverviewDto(updatedProfileOverview);

    return profileOverviewDto;
}