import { AxiosStatic, AxiosRequestConfig, AxiosError } from 'axios';
import { Store } from "redux";
import { isEmpty } from '../Helpers/StringHelpers';
import AuthenticationService from './AuthenticationService';

/**
 * Use axios interceptors to add additional behavior to API calls.
 *
 * For requests add Bearer tokens to authenticate api calls.
 * If the token has expired then get a new token.
 *
 * For response, handle HTTP 401 Unuthorized results by displaying an alert error message.
 *
 * @param axios The axios reference that we want to configure.
 * @param store The redux store reference.
 */

const setupAxios = (axios: AxiosStatic, store: Store): void => {
    axios.interceptors.request.use(async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {

        let currentUser = store.getState().currentUser;
        let accessToken = currentUser.accessToken;
        let accessTokenExpiresOn = new Date(currentUser.accessTokenExpiresOn);

        // Make sure there is a value for an access token and that the expiration date of the token is in the future
        if (!isEmpty(accessToken)) {
            if ((accessTokenExpiresOn instanceof Date) && (accessTokenExpiresOn > new Date())) {
                config.headers.Authorization = `Bearer ${accessToken}`;
            } else {
                const authenticationService = new AuthenticationService();
                const updatedAccessToken = await authenticationService.getCandidateManagerApiTokenSilent(store);
                config.headers.Authorization = `Bearer ${updatedAccessToken}`;
            }
        }

        return config;

    }, (error: AxiosError) => {
        return Promise.reject(error);
    });
};

export default setupAxios;

