import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./services/authConfig";
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import axios from 'axios';
import setupAxios from './services/SetupAxios';
import { store } from './store/configureStore';

const rootElement = document.getElementById('root');

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance: PublicClientApplication = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: any) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event?.payload?.account) {
        const account = event?.payload?.account;
        msalInstance.setActiveAccount(account);
        setupAxios(axios, store);
    }

    if (event.eventType === EventType.LOGIN_FAILURE) {
        console.log("Event failure: " + JSON.stringify(event));
    }
});

ReactDOM.render(
    <React.Fragment>
        <Provider store={store}>
            <App instance={msalInstance} />
        </Provider>
    </React.Fragment>,
    rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
