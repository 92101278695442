import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import {
    Client, IUpdatePreSuperDayGridCommand, IUpdatePreSuperDayGridVm, PreSuperDayCandidateDto, UpdatePreSuperDayGridCandidateDto, UpdatePreSuperDayGridCommand, UpdatePreSuperDayGridVm

} from '../../Api/client-api';

const api = new Client("", axios);

export interface IPreSuperDayGridCandidates {
    candidates: PreSuperDayCandidateDto[] | null,
    status: string,
};

const initialState: IPreSuperDayGridCandidates = {
    candidates: null,
    status: '',
};

export const getPreSuperDayGrid = createAsyncThunk(
    'PreSuperDay/GetPreSuperDayGrid',
    async () => {
        let response = null;
        response = await (await api.getPreSuperDayGrid()).toJSON();
        return response;
    }
);

export const downloadCandidateResume = createAsyncThunk(
    'Candidates/DownloadCandidateResume?',
    async (candidateId: number) => {
        let response = null;
        response = await api.downloadCandidateResume(candidateId);
        return response.data;
    }
);

export const savePreSuperDayGridChanges = createAsyncThunk(
    'PreSuperDay/PutCandidatesGrid',
    async (candidates: PreSuperDayCandidateDto[], thunkAPI) => {
        let changedCandidates = new Array<UpdatePreSuperDayGridCandidateDto>();
        let updated = false;
        originalCandidateGridState.forEach((ogCandidate: any) => {
            let compareCandidate = candidates.find((c) => c.canManId === ogCandidate.canManId);

            if (JSON.stringify(ogCandidate) !== JSON.stringify(compareCandidate)) {

                let updatedCandidateDto = new UpdatePreSuperDayGridCandidateDto(compareCandidate);
                updated = true;

                changedCandidates.push(updatedCandidateDto)
            }
        });

        if (changedCandidates && updated) {

            let updatedGridCandidates: IUpdatePreSuperDayGridVm = {
                candidates: changedCandidates
            }

            let updatedGridCandidatesVm = new UpdatePreSuperDayGridVm(updatedGridCandidates);

            let updateGridCandidatesCommandInterface: IUpdatePreSuperDayGridCommand = {
                preSuperDayGridVm: updatedGridCandidatesVm
            }

            let putCommand: UpdatePreSuperDayGridCommand = new UpdatePreSuperDayGridCommand(updateGridCandidatesCommandInterface)
            await api.putCandidatesGrid2(putCommand);
        }

    }
);

let originalCandidateGridState: any;

export const preSuperDaySlice = createSlice({
    name: 'preSuperDay',
    initialState,
    reducers: {
        setPreSuperDayGrid: (state, action) => {
        },
        updatePreSuperDayGrid: (state, action) => {
            state.candidates = action.payload;

        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPreSuperDayGrid.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPreSuperDayGrid.fulfilled, (state, action) => {
                if (action?.payload) {
                    originalCandidateGridState = cloneDeep(action.payload.candidates);
                    state.candidates = action.payload.candidates
                    state.status = 'idle';
                }
                // TODO handle error
            })
            .addCase(getPreSuperDayGrid.rejected, (state, action) => {
                state.status = 'idle';
            })
    },
});

export const { setPreSuperDayGrid, updatePreSuperDayGrid } = preSuperDaySlice.actions

export default preSuperDaySlice.reducer;