import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from "axios";
import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteCloseEvent } from "@progress/kendo-react-dropdowns";
import { Client } from "../../Api/client-api";
import { useMsal } from '@azure/msal-react';
import { appRoles, hasPermissionByAppRole } from '../../services/authConfig';
import { getShowOldCandidates } from "../Utils/cookies";

const api = new Client("", axios);

export const CandidateSearchBox = () => {

    const { instance } = useMsal();
    const history = useHistory();
    const [searchList, setSearchList] = useState<any>([]);

    const handleChange = (event: AutoCompleteChangeEvent) => {
        const showOldCandidates = getShowOldCandidates() && hasPermissionByAppRole(instance, [appRoles.SuperAdmin]);
        api.searchBarRequest(event.value, showOldCandidates)
            .then((response: any) => {
                if (response.searchResults) {
                    response.searchResults.forEach((item: any) => {
                        item.text = `${item.id}, ${item.preferredName}`;
                        if (item.recruitingYear !== null) {
                            item.text += `, ${item.recruitingYear}`;
                        }
                    });
                    setSearchList(response.searchResults);
                }
            })
    };

    const handleClose = (event: AutoCompleteCloseEvent) => {
        let found = searchList.find((item: any) => item.text === event.target.value);
        if (found) {
            history.push(
                `/candidates/candidateprofile/${found.id}`
            )
        }
    };

    return (
        <>
            <AutoComplete
                className="search-box"
                data={searchList}
                textField="text"
                placeholder="Search..."
                onChange={handleChange}
                onClose={handleClose}
            />
        </>
    );
};