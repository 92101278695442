import { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { Menu, MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { RouteConfig } from './../Routes/route-config'
import { isSafari } from 'react-device-detect';

const NavMenu = (props: any) => {
    const onSelect = (event: MenuSelectEvent) => {
        props.history.push(event.item.data.route);
    };

    const pathCandidates = "/candidates"
    const pathCandidateFeedback = "/candidatefeedback"
    // const pathPreSuperDay = "/presuperday"
    // const pathSuperDay = "/superday"
    const pathScheduler = "/scheduling";
    const pathNewsFeed = "/newsfeed";
    const pathEmployees = "/employees";
    const pathUsers = "/users";
    const pathAdmin = "/admin";

    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [canViewCandidatesMenu, setCanViewCandidatesMenu] = useState(false);
    const [canViewCandidateFeedbackMenu, setCanViewCandidateFeedbackMenu] = useState(false);
    // const [canViewPreSuperDayMenu, setCanViewPreSuperDayMenu] = useState(false);
    // const [canViewSuperDayMenu, setCanViewSuperDayMenu] = useState(false);
    const [canViewSchedulerMenu, setCanViewSchedulerMenu] = useState(false);
    const [canViewNewsFeedMenu, setCanViewNewsFeedMenu] = useState(false);
    const [canViewEmployeeMenu, setCanViewEmployeeMenu] = useState(false);
    const [canViewUsersMenu, setCanViewUsersMenu] = useState(false);
    const [canViewAdminMenu, setCanViewAdminMenu] = useState(false);

    const initializePermissions = () => {
        const currentAccount = instance.getActiveAccount();
        if (currentAccount?.idTokenClaims) {
            var idTokenClaims: any = currentAccount.idTokenClaims;
            setCanViewCandidatesMenu(isMenuVisible(idTokenClaims, pathCandidates));
            setCanViewCandidateFeedbackMenu(isMenuVisible(idTokenClaims, pathCandidateFeedback));
            // setCanViewPreSuperDayMenu(isMenuVisible(idTokenClaims, pathPreSuperDay));
            // setCanViewSuperDayMenu(isMenuVisible(idTokenClaims, pathSuperDay));
            setCanViewSchedulerMenu(isMenuVisible(idTokenClaims, pathScheduler));
            setCanViewNewsFeedMenu(isMenuVisible(idTokenClaims, pathNewsFeed));
            setCanViewEmployeeMenu(isMenuVisible(idTokenClaims, pathEmployees));
            setCanViewUsersMenu(isMenuVisible(idTokenClaims, pathUsers));
            setCanViewAdminMenu(isMenuVisible(idTokenClaims, pathAdmin));
        }
    }

    function isMenuVisible(idTokenClaims: any, path: string) {
        let roles = RouteConfig.find(route => route.path === path)?.roles || [];
        let intersection = roles.filter((role: any) => idTokenClaims?.roles.includes(role));
        return intersection.length > 0;
    }

    useEffect(() => {
        initializePermissions();
    });

    return (
        <>
            <Menu className="nav-menu" onSelect={onSelect}>
                {/* TODO: Refactor to use the routes from RouteConfig */}
                {isAuthenticated && canViewCandidatesMenu &&
                    <MenuItem
                        text="Candidates"
                        data={{ route: "/candidates" }}
                        cssClass={window.location.pathname === "/" || window.location.pathname === "/candidates" || window.location.pathname.startsWith("/candidates/candidateprofile") ? "active" : ""}
                    />
                }
                {isAuthenticated && canViewCandidateFeedbackMenu &&
                    <MenuItem
                        text="Candidate Feedback"
                        data={{ route: "/candidatefeedback" }}
                        cssClass={window.location.pathname === "/candidatefeedback" ? "active" : ""}
                    />
                }
                {/* {isAuthenticated && canViewPreSuperDayMenu &&
                    <MenuItem
                        text="Pre Super Day"
                        data={{ route: "/presuperDay" }}
                        cssClass={window.location.pathname === "/presuperDay" ? "active" : ""}
                    />
                }
                {isAuthenticated && canViewSuperDayMenu &&
                    <MenuItem
                        text="Super Day"
                        data={{ route: "/superDay" }}
                        cssClass={window.location.pathname === "/superDay" ? "active" : ""}
                    />
                } */}
                {isAuthenticated && canViewSchedulerMenu && !isSafari &&
                    <MenuItem
                        text="Scheduling"
                        data={{ route: pathScheduler }}
                        cssClass={window.location.pathname === pathScheduler ? "active" : ""}
                    />
                }
                {isAuthenticated && canViewNewsFeedMenu && !isSafari &&
                    <MenuItem
                        text="Newsfeed"
                        data={{ route: pathNewsFeed }}
                        cssClass={window.location.pathname === pathNewsFeed ? "active" : ""}
                    />
                }
                {isAuthenticated && canViewEmployeeMenu && !isSafari &&
                    <MenuItem
                        text="Employees"
                        data={{ route: pathEmployees }}
                        cssClass={window.location.pathname === pathEmployees || window.location.pathname.startsWith(`${pathEmployees}/employeeprofile`) ? "active" : ""}
                    />
                }
                {isAuthenticated && canViewUsersMenu && !isSafari &&
                    <MenuItem
                        text="Users"
                        data={{ route: pathUsers }}
                        cssClass={window.location.pathname === pathUsers ? "active" : ""}
                    />
                }
                {isAuthenticated && canViewAdminMenu && !isSafari &&
                    <MenuItem
                        text="Admin"
                        data={{ route: pathAdmin }}
                        cssClass={window.location.pathname === pathAdmin ? "active" : ""}
                    />
                }
            </Menu>
        </>
    );
}

export default withRouter(NavMenu);