import React from 'react';
import { AppBar, AppBarSection, AppBarSpacer } from "@progress/kendo-react-layout";

const Footer = () => {
    return (
        <AppBar className="footer" positionMode="fixed" position="bottom">
            <AppBarSection>
                CLAYTON, DUBLIER & RICE, LLC 375 PARK AVENUE, FL 18 NEW YORK, NY 10152
            </AppBarSection>
            <AppBarSpacer />
            <AppBarSection>
                <a href="https://www.cdr-inc.com" target="_blank" rel="noreferrer">www.cdr-inc.com</a>&nbsp;| {process.env.REACT_APP_AZAD_VERSION}
            </AppBarSection>
        </AppBar>
    )
}

export default Footer;