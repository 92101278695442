import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { Client } from '../../Api/client-api';

const api = new Client("", axios);

export interface ICandidatesProgress {
    status: string,
    candidatesProgress: any | null
}

const initialState: ICandidatesProgress = {
    status: '',
    candidatesProgress: null,
};

export const getCandiatesProgress = createAsyncThunk(
    'Scheduling/GetCandidatesProgress',
    async () => {
        let response;
        response = await (await api.getCandidatesProgress()).toJSON();
        return response;
    }
);

export const candidatesProgressSlice = createSlice({
    name: 'candidatesProgress',
    initialState,
    reducers: {
        getCandiatesProgress: (state, action) => {
            state.candidatesProgress = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCandiatesProgress.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getCandiatesProgress.fulfilled, (state, action) => {
                state.status = 'idle';
                state.candidatesProgress = action.payload.candidateProgressDtos
            })
            .addCase(getCandiatesProgress.rejected, (state) => {
                state.status = 'idle';
            })
    },
});

export default candidatesProgressSlice.reducer;