import { IPublicClientApplication } from '@azure/msal-browser';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { Client, CreateCandidateInterviewCommand, ICreateCandidateInterviewCommand, CandidateInterviewDto, SchedulingCandidateDto, SchedulingEmployeeDto, SchedulingInterviewTypeDto, SchedulingAvailabilityTypeDto, IUpdateCandidateInterviewCommand, UpdateCandidateInterviewCommand, ICreateEmployeeAvailabilityCommand, CreateEmployeeAvailabilityCommand, IUpdateEmployeeAvailabilityCommand, UpdateEmployeeAvailabilityCommand, EmployeeAvailabilityDto } from '../../../Api/client-api';
import { appRoles, hasPermissionByAppRole } from '../../../services/authConfig';
import { getShowOldCandidates } from '../../Utils/cookies';

const api = new Client("", axios);
export interface ISharedForm {
    schedulingEvent: number | null,
    candidate: number | null,
    employee: number | null,
    interviewType: number | null,
    startTime: string | null,
    endTime: string | null,
    didShowUp: boolean | null,
    availabilityType: number | null,
}
export interface IScheduler {
    schedulingCandidates: SchedulingCandidateDto[] | null,
    candidatesStatus: string,
    schedulingEmployees: SchedulingEmployeeDto[] | null,
    schedulingAvailability: SchedulingAvailabilityTypeDto[] | null,
    interviewTypes: SchedulingInterviewTypeDto[] | null,
    status: string,
    sharedFormData: ISharedForm,
}

const initialState: IScheduler = {
    schedulingCandidates: null,
    candidatesStatus: '',
    schedulingEmployees: null,
    schedulingAvailability: null,
    interviewTypes: null,
    status: '',
    sharedFormData: {
        employee: null,
        availabilityType: null,
        startTime: null,
        endTime: null,
        schedulingEvent: null,
        candidate: null,
        interviewType: null,
        didShowUp: null,
    },
};

export const getSchedulerCandidates = createAsyncThunk(
    'Scheduling/GetSchedulerCandidates',
    async (instance: IPublicClientApplication) => {
        const showOldCandidates = getShowOldCandidates() && hasPermissionByAppRole(instance, [appRoles.SuperAdmin]);
        let response = (await api.getSchedulingCandidates(showOldCandidates)).toJSON();
        return response;
    }
);

const getVerticalInterest = (verticalInterest: string) => {
    switch (verticalInterest) {
        case "Healthcare":
            return "(H)";
        case "FinancialServices":
            return "(F)";
        case "Industrials":
            return "(I)";
        case "Retail and Consumer":
            return "(RC)";
        case "Tech":
            return "(T)";
        default:
            return "";
    }
}

export const getSchedulerEmployees = createAsyncThunk(
    'Scheduling/GetSchedulerEmployees',
    async () => {
        let response = await (await api.getSchedulingEmployees()).toJSON();
        let retailAndConsumer: any[] = [];
        let technology: any[] = [];
        let industrials: any[] = [];
        let financialServices: any[] = [];
        let healthcare: any[] = [];
        let empty: any[] = [];
        let all: any[] = [];

        response.schedulingEmployees.map(function (e: any) {
            switch (e.verticalInterest) {
                case "Healthcare":
                    healthcare.push(e);
                    break;
                case "FinancialServices":
                    financialServices.push(e);
                    break;
                case "Industrials":
                    industrials.push(e);
                    break;
                case "Retail and Consumer":
                    retailAndConsumer.push(e);
                    break;
                case "Tech":
                    technology.push(e);
                    break;
                default:
                    empty.push(e)
                    break;
            }
            e.text = `${e.preferredName} ${getVerticalInterest(e.verticalInterest)}`;   // Required to list employees in the scheduler's y-axis

            return e;
        })


        retailAndConsumer.sort(function (employee1: any, employee2: any) {
            return employee1.preferredName === employee2.preferredName ? 0 : employee1.preferredName > employee2.preferredName ? 1 : -1;
        })
        industrials.sort(function (employee1: any, employee2: any) {
            return employee1.preferredName === employee2.preferredName ? 0 : employee1.preferredName > employee2.preferredName ? 1 : -1;
        })
        technology.sort(function (employee1: any, employee2: any) {
            return employee1.preferredName === employee2.preferredName ? 0 : employee1.preferredName > employee2.preferredName ? 1 : -1;
        })
        healthcare.sort(function (employee1: any, employee2: any) {
            return employee1.preferredName === employee2.preferredName ? 0 : employee1.preferredName > employee2.preferredName ? 1 : -1;
        })
        financialServices.sort(function (employee1: any, employee2: any) {
            return employee1.preferredName === employee2.preferredName ? 0 : employee1.preferredName > employee2.preferredName ? 1 : -1;
        })
        empty.sort(function (employee1: any, employee2: any) {
            return employee1.preferredName === employee2.preferredName ? 0 : employee1.preferredName > employee2.preferredName ? 1 : -1;
        })

        return response = all.concat(financialServices, healthcare, industrials, technology, retailAndConsumer, empty);
    }
)

export const getSchedulerAvailabilityTypes = createAsyncThunk(
    'Scheduling/GetAvailabilityTypes',
    async () => {
        let response = await (await api.getSchedulingAvailibilityTypes()).toJSON();
        return response;
    }
);

export const getSchedulerInterviewTypes = createAsyncThunk(
    'Scheduling/GetInterviewTypes',
    async () => {
        let response = await (await api.getSchedulingInterviewTypes()).toJSON();
        return response;
    }
);

export const createCandidateInterview = createAsyncThunk(
    'Scheduling/CreateCandidateInterview',
    async (CandidateInterview: any) => {
        let NewCandidateInterview = new CandidateInterviewDto(CandidateInterview);
        let createCandidateInterviewCommand: ICreateCandidateInterviewCommand = {
            candidateInterview: NewCandidateInterview
        }
        let createCandidateCommand: CreateCandidateInterviewCommand = new CreateCandidateInterviewCommand(createCandidateInterviewCommand);
        let response = await (await api.createCandidateInterview(createCandidateCommand)).toJSON();
        return response;
    }
)

export const createEmployeeAvailability = createAsyncThunk(
    'Scheduling/CreateEmployeeAvailability',
    async (EmployeeAvailability: any) => {
        let employeeAvailabilityDto = new EmployeeAvailabilityDto(EmployeeAvailability);
        let createEmployeeAvailabilityCommand: ICreateEmployeeAvailabilityCommand = {
            employeeAvailability: employeeAvailabilityDto
        }
        let createEmployeeCommand: CreateEmployeeAvailabilityCommand = new CreateEmployeeAvailabilityCommand(createEmployeeAvailabilityCommand);
        let response = await (await api.createEmployeeAvailability(createEmployeeCommand)).toJSON();
        return response;
    }
)

export const updateCandidateInterview = createAsyncThunk(
    'Scheduling/UpdateCandidateInterview',
    async (CandidateInterview: any) => {
        let UpdatedCandidateInterview = new CandidateInterviewDto(CandidateInterview);
        let updateCandidateInterviewCommand: IUpdateCandidateInterviewCommand = {
            candidateInterview: UpdatedCandidateInterview
        }
        let updatedCandidateInterviewCommand: UpdateCandidateInterviewCommand = new UpdateCandidateInterviewCommand(updateCandidateInterviewCommand);
        let response = await (await api.updateCandidateInterview(updatedCandidateInterviewCommand)).toJSON();
        return response;
    }
)

export const updateEmployeeAvailability = createAsyncThunk(
    'Scheduling/UpdateEmployeeAvailability',
    async (EmployeeAvailability: any) => {
        let UpdatedEmployeeAvailability = new EmployeeAvailabilityDto(EmployeeAvailability);
        let updateEmployeeAvailabilityCommand: IUpdateEmployeeAvailabilityCommand = {
            employeeAvailability: UpdatedEmployeeAvailability
        }
        let updatedEmployeeAvailabilityCommand: UpdateEmployeeAvailabilityCommand = new UpdateEmployeeAvailabilityCommand(updateEmployeeAvailabilityCommand);
        let response = await (await api.updateEmployeeAvailability(updatedEmployeeAvailabilityCommand)).toJSON();
        return response;
    }
)

export const deleteSchedulingEvent = createAsyncThunk(
    'Scheduling/DeleteSchedulingEvent',
    async (id: number | undefined) => {
        await api.deleteSchedulingEvent(id);
    }
);

export const candidateSchedulingSlice = createSlice({
    name: 'scheduling',
    initialState,
    reducers: {
        updateCandidateEditor: (state, action) => {
            state.sharedFormData.candidate = action.payload
        },
        updateEmployeeEditor: (state, action) => {
            state.sharedFormData.employee = action.payload
        },
        updateInterviewTypeEditor: (state, action) => {
            state.sharedFormData.interviewType = action.payload
        },
        updateStartTime: (state, action) => {
            state.sharedFormData.startTime = action.payload
        },
        updateEndTime: (state, action) => {
            state.sharedFormData.endTime = action.payload
        },
        updateShowUp: (state, action) => {
            state.sharedFormData.didShowUp = action.payload
        },
        updateAvailabilityTypeEditor: (state, action) => {
            state.sharedFormData.availabilityType = action.payload
        },
        getSchedulerCandidates: (state, action) => {
            state.schedulingCandidates = action.payload
        },
        getSchedulerEmployees: (state, action) => {
            state.schedulingEmployees = action.payload
        },
        getSchedulerInterviewTypes: (state, action) => {
            state.interviewTypes = action.payload
        },
        saveNewInterview: (state, action) => {
            state.status = "idle"
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSchedulerCandidates.pending, (state) => {
                state.candidatesStatus = 'loading';
            })
            .addCase(getSchedulerCandidates.fulfilled, (state, action) => {
                state.candidatesStatus = 'idle';
                state.schedulingCandidates = action.payload.schedulingCandidates
            })
            .addCase(getSchedulerCandidates.rejected, (state) => {
                state.candidatesStatus = 'idle';
            })
            .addCase(getSchedulerEmployees.pending, (state) => {
                state.candidatesStatus = 'loading';
            })
            .addCase(getSchedulerEmployees.fulfilled, (state, action) => {
                state.candidatesStatus = 'idle';
                state.schedulingEmployees = action.payload
            })
            .addCase(getSchedulerEmployees.rejected, (state) => {
                state.candidatesStatus = 'idle';
            })
            .addCase(getSchedulerInterviewTypes.pending, (state) => {
                state.candidatesStatus = 'loading';
            })
            .addCase(getSchedulerInterviewTypes.fulfilled, (state, action) => {
                state.candidatesStatus = 'idle';
                state.interviewTypes = action.payload.schedulingInterviewTypes
            })
            .addCase(getSchedulerInterviewTypes.rejected, (state) => {
                state.candidatesStatus = 'idle';
            })
            .addCase(getSchedulerAvailabilityTypes.pending, (state) => {
                state.candidatesStatus = 'loading';
            })
            .addCase(getSchedulerAvailabilityTypes.fulfilled, (state, action) => {
                state.candidatesStatus = 'idle';
                state.schedulingAvailability = action.payload.schedulingAvailabilityTypes
            })
            .addCase(getSchedulerAvailabilityTypes.rejected, (state) => {
                state.candidatesStatus = 'idle';
            })
            .addCase(createCandidateInterview.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createCandidateInterview.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(createCandidateInterview.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(createEmployeeAvailability.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createEmployeeAvailability.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(createEmployeeAvailability.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(updateCandidateInterview.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateCandidateInterview.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(updateCandidateInterview.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(updateEmployeeAvailability.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateEmployeeAvailability.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(updateEmployeeAvailability.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(deleteSchedulingEvent.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteSchedulingEvent.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(deleteSchedulingEvent.rejected, (state, action) => {
                state.status = 'idle';
            })
    },
});

export const { updateCandidateEditor, updateEmployeeEditor, updateInterviewTypeEditor, updateStartTime, updateEndTime, updateShowUp, updateAvailabilityTypeEditor } = candidateSchedulingSlice.actions

export default candidateSchedulingSlice.reducer;