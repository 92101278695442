import React from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { Switch } from 'react-router';
import IRoute from "../../Interfaces/IRoute";
import RouteWithSubRoutes from "./RouteWithSubRoutes";

interface IProps {
    routes: IRoute[];
    msalInstance: PublicClientApplication;
}

const Router: React.FC<IProps> = (props) => {
    return <Switch>{props.routes.map((route: IRoute) => <RouteWithSubRoutes key={route.path} {...route} />)}</Switch>;
};

export default Router;