import React from 'react';
import IRoute from '../../Interfaces/IRoute';
import { appRoles } from '../../services/authConfig';

const Candidates = React.lazy(() => import('../Candidates/Candidates'));
const DeDupe = React.lazy(() => import('../Candidates/DeDupe/DeDupe'));
const CandidateFeedback = React.lazy(() => import('../CandidateFeedback/CandidateFeedback')) ;
const BehavioralInterviewOne = React.lazy(() => import('../Interviews/BehavioralInterviewOne')) ;
const BehavioralInterviewTwo = React.lazy(() => import('../Interviews/BehavioralInterviewTwo')) ;
const BehavioralInterviewThree = React.lazy(() => import('../Interviews/BehavioralInterviewThree')) ;
const BehavioralInterviewFour = React.lazy(() => import('../Interviews/BehavioralInterviewFour')) ;
const LboModelingInterview = React.lazy(() => import('../Interviews/LboModelinglInterview')) ;
const CaseInterview = React.lazy(() => import('../Interviews/CaseInterview')) ;
const BonusCaseInterview = React.lazy(() => import('../Interviews/BonusCaseInterview')) ;
const CoffeeInterview = React.lazy(() => import('../Interviews/CoffeeChatInterview')) ;
const EventInterview = React.lazy(() => import('../Interviews/EventInterview')) ;
const InformalFeedback = React.lazy(() => import('../Interviews/InformalInterview')) ;
const Users = React.lazy(() => import('../Users/Users')) ;
const SampleStyles = React.lazy(() => import('../SampleStyles/SampleStyles')) ;
const CandidateProfile = React.lazy(() => import('../CandidateProfile/CandidateProfile')) ;
const Admin = React.lazy(() => import('../Admin/Admin')) ;
const ReleaseNotes = React.lazy(() => import('../Admin/MainTab/ReleaseNotes'));
// const PreSuperDay = React.lazy(() => import('../PreSuperDay/PreSuperDay')) ;
// const SuperDay = React.lazy(() => import('../SuperDay/SuperDay')) ;
const NewsFeed = React.lazy(() => import('../NewsFeed/NewsFeed')) ;
const Employees = React.lazy(() => import('../Employees/Employees')) ;
const EmployeeProfile = React.lazy(() => import('../EmployeeProfile/EmployeeProfile')) ;
const CdrScheduler = React.lazy(() => import('../CdrScheduler/CdrScheduler'));
const InformalLboReview = React.lazy(() => import('../Interviews/InformalLboReview'));

export const RouteConfig: IRoute[] = [
    {
        path: "/",
        component: Candidates,
        breadcrumb: "CanMan",
        exact: true,
        private: false,
        roles: [appRoles.LiteGeneral, appRoles.HeavyGeneral, appRoles.SuperAdmin, appRoles.HeavyAdmin, appRoles.NormalAdmin, appRoles.LiteAdmin]
    },
    {
        path: "/candidates",
        component: Candidates,
        breadcrumb: "Candidates",
        exact: true,
        private: true,
        roles: [appRoles.LiteGeneral, appRoles.HeavyGeneral, appRoles.SuperAdmin, appRoles.HeavyAdmin, appRoles.NormalAdmin, appRoles.LiteAdmin]
    },
    {
        path: "/candidates/dedupe",
        component: DeDupe,
        breadcrumb: "DeDupe",
        exact: true,
        private: true,
        roles: [appRoles.SuperAdmin]
    },
    {
        path: "/candidatefeedback",
        component: CandidateFeedback,
        breadcrumb: "Candidate Feedback",
        exact: true,
        private: true,
        roles: [appRoles.LiteGeneral, appRoles.HeavyGeneral, appRoles.SuperAdmin, appRoles.HeavyAdmin, appRoles.NormalAdmin, appRoles.LiteAdmin]
    },
    {
        path: "/candidatefeedback/Informal",
        component: InformalFeedback,
        breadcrumb: "Informal Interview",
        exact: true,
        private: true,
        roles: [appRoles.LiteGeneral, appRoles.HeavyGeneral, appRoles.SuperAdmin, appRoles.HeavyAdmin, appRoles.NormalAdmin, appRoles.HeavyAdmin, appRoles.LiteAdmin]
    },
    {
        path: "/candidatefeedback/BI1",
        component: BehavioralInterviewOne,
        breadcrumb: "Behavioral Interview #1",
        exact: true,
        private: true,
        roles: [appRoles.SuperAdmin, appRoles.HeavyGeneral, appRoles.HeavyAdmin, appRoles.NormalAdmin]
    },
    {
        path: "/candidatefeedback/BI2",
        component: BehavioralInterviewTwo,
        breadcrumb: "Behavioral Interview #2",
        exact: true,
        private: true,
        roles: [appRoles.SuperAdmin, appRoles.HeavyGeneral, appRoles.HeavyAdmin, appRoles.NormalAdmin]
    },
    {
        path: "/candidatefeedback/BI3",
        component: BehavioralInterviewThree,
        breadcrumb: "Behavioral Interview #3",
        exact: true,
        private: true,
        roles: [appRoles.SuperAdmin, appRoles.HeavyGeneral, appRoles.HeavyAdmin, appRoles.NormalAdmin]
    },
    {
        path: "/candidatefeedback/BI4",
        component: BehavioralInterviewFour,
        breadcrumb: "Behavioral Interview #4",
        exact: true,
        private: true,
        roles: [appRoles.SuperAdmin, appRoles.HeavyGeneral, appRoles.HeavyAdmin, appRoles.NormalAdmin]
    },
    {
        path: "/candidatefeedback/LBO",
        component: LboModelingInterview,
        breadcrumb: "Behavioral Interview LBO",
        exact: true,
        private: true,
        roles: [appRoles.SuperAdmin, appRoles.HeavyGeneral, appRoles.HeavyAdmin, appRoles.NormalAdmin]
    },
    {
        path: "/candidatefeedback/Case",
        component: CaseInterview,
        breadcrumb: "Behavioral Interview Case",
        exact: true,
        private: true,
        roles: [appRoles.SuperAdmin, appRoles.HeavyGeneral, appRoles.HeavyAdmin, appRoles.NormalAdmin]
    },
    {
        path: "/candidatefeedback/Bonuscase",
        component: BonusCaseInterview,
        breadcrumb: "Behavioral Interview Bonus Case",
        exact: true,
        private: true,
        roles: [appRoles.SuperAdmin, appRoles.HeavyGeneral, appRoles.HeavyAdmin, appRoles.NormalAdmin]
    },
    {
        path: "/candidatefeedback/Coffee",
        component: CoffeeInterview,
        breadcrumb: "Coffee Chat",
        exact: true,
        private: true,
        roles: [appRoles.SuperAdmin, appRoles.HeavyGeneral, appRoles.HeavyAdmin, appRoles.NormalAdmin]
    },
    {
        path: "/candidatefeedback/Event",
        component: EventInterview,
        breadcrumb: "Behavioral Interview Event",
        exact: true,
        private: true,
        roles: [appRoles.SuperAdmin, appRoles.HeavyGeneral, appRoles.HeavyAdmin, appRoles.NormalAdmin]
    },
    {
        path: "/candidatefeedback/InformalLboReview",
        component: InformalLboReview,
        breadcrumb: "Informal LBO Review",
        exact: true,
        private: true,
        roles: [appRoles.LiteGeneral, appRoles.HeavyGeneral, appRoles.SuperAdmin, appRoles.HeavyAdmin, appRoles.NormalAdmin, appRoles.HeavyAdmin, appRoles.LiteAdmin]
    },
    {
        path: "/candidates/candidateprofile",
        component: CandidateProfile,
        breadcrumb: "Candidate Profile",
        exact: true,
        private: true,
        roles: [appRoles.LiteGeneral, appRoles.HeavyGeneral, appRoles.SuperAdmin, appRoles.HeavyAdmin, appRoles.NormalAdmin, appRoles.HeavyAdmin, appRoles.LiteAdmin]
    },
    {
        path: "/candidates/candidateprofile/:id",
        component: CandidateProfile,
        exact: true,
        private: true,
        roles: [appRoles.LiteGeneral, appRoles.HeavyGeneral, appRoles.SuperAdmin, appRoles.HeavyAdmin, appRoles.NormalAdmin, appRoles.LiteAdmin]
    },
    // {
    //     path: "/presuperday",
    //     component: PreSuperDay,
    //     breadcrumb: "Pre Super Day",
    //     exact: true,
    //     private: true,
    //     roles: [appRoles.SuperAdmin, appRoles.HeavyGeneral, appRoles.HeavyAdmin, appRoles.NormalAdmin]
    // },
    // {
    //     path: "/superday",
    //     component: SuperDay,
    //     breadcrumb: "Super Day",
    //     exact: true,
    //     private: true,
    //     roles: [appRoles.SuperAdmin, appRoles.HeavyGeneral, appRoles.HeavyAdmin, appRoles.NormalAdmin]
    // },
    {
        path: "/users",
        component: Users,
        breadcrumb: "Users",
        exact: true,
        private: true,
        roles: [appRoles.SuperAdmin, appRoles.HeavyAdmin, appRoles.NormalAdmin, appRoles.LiteAdmin, appRoles.HeavyGeneral, appRoles.LiteGeneral]
    },
    {
        path: "/admin",
        component: Admin,
        breadcrumb: "Admin",
        exact: true,
        private: true,
        roles: [appRoles.SuperAdmin]
    },
    {
        path: "/releasenotes",
        component: ReleaseNotes,
        breadcrumb: "Release Notes",
        exact: true,
        private: true,
        roles: [appRoles.SuperAdmin, appRoles.HeavyGeneral, appRoles.HeavyAdmin, appRoles.NormalAdmin]
    },
    {
        path: "/sample-styles",
        component: SampleStyles,
        breadcrumb: "SampleStyles",
        exact: true,
        private: true,
        roles: [appRoles.SuperAdmin, appRoles.HeavyGeneral, appRoles.HeavyAdmin, appRoles.NormalAdmin]
    },
    {
        path: "/scheduling",
        component: CdrScheduler,
        breadcrumb: "Scheduling",
        exact: true,
        private: true,
        roles: [appRoles.LiteGeneral, appRoles.HeavyGeneral, appRoles.LiteAdmin, appRoles.NormalAdmin, appRoles.HeavyAdmin, appRoles.SuperAdmin]
    },
    {
        path: "/newsfeed",
        component: NewsFeed,
        breadcrumb: "Newsfeed",
        exact: true,
        private: true,
        roles: [appRoles.SuperAdmin]
    },
    {
        path: "/employees",
        component: Employees,
        breadcrumb: "Employees",
        exact: true,
        private: true,
        roles: [appRoles.SuperAdmin]
    },
    {
        path: "/employees/employeeprofile",
        component: EmployeeProfile,
        breadcrumb: "Employee Profile",
        exact: true,
        private: true,
        roles: [appRoles.SuperAdmin]
    },
    {
        path: "/employees/employeeprofile/:id",
        component: EmployeeProfile,
        exact: true,
        private: true,
        roles: [appRoles.SuperAdmin]
    }
];
