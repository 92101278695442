// NAMES
export const initialNameResume = {
    id: null,
    fullName: "",
    alternateNameSortOrder: null,
    isPrimary: true,
    isResumeName: true,

    // fields not part of the DTO
    isDirtyName: false
}
export const initialNameAlt1 = {
    id: null,
    fullName: "",
    alternateNameSortOrder: 1,
    isPrimary: false,
    isResumeName: false,

    // fields not part of the DTO
    isDirtyName: false
}
export const initialNameAlt2 = {
    id: null,
    fullName: "",
    alternateNameSortOrder: 2,
    isPrimary: false,
    isResumeName: false,

    // fields not part of the DTO
    isDirtyName: false
}
export const initialNameAlt3 = {
    id: null,
    fullName: "",
    alternateNameSortOrder: 3,
    isPrimary: false,
    isResumeName: false,

    // fields not part of the DTO
    isDirtyName: false
}
export const initialNameAlt4 = {
    id: null,
    fullName: "",
    alternateNameSortOrder: 4,
    isPrimary: false,
    isResumeName: false,

    // fields not part of the DTO
    isDirtyName: false
}

// EMAILS
export const initialEmail1 = {
    id: null,
    email: "",
    sortOrder: 1,
    isPrimary: true,

    // fields not part of the DTO
    isDirtyEmail: false
}
export const initialEmail2 = {
    id: null,
    email: "",
    sortOrder: 2,
    isPrimary: false,

    // fields not part of the DTO
    isDirtyEmail: false
}
export const initialEmail3 = {
    id: null,
    email: "",
    sortOrder: 3,
    isPrimary: false,

    // fields not part of the DTO
    isDirtyEmail: false
}
export const initialEmail4 = {
    id: null,
    email: "",
    sortOrder: 4,
    isPrimary: false,

    // fields not part of the DTO
    isDirtyEmail: false
}
export const initialEmail5 = {
    id: null,
    email: "",
    sortOrder: 5,
    isPrimary: false,

    // fields not part of the DTO
    isDirtyEmail: false
}

// PHONES
export const initialPhone1 = {
    id: null,
    number: "",
    sortOrder: 1,
    isPrimary: true,
    smsEnabled: false,

    // fields not part of the DTO
    isDirtyPhone: false,
    isDirtySms: false,
}
export const initialPhone2 = {
    id: null,
    number: "",
    sortOrder: 2,
    isPrimary: false,
    smsEnabled: false,

    // fields not part of the DTO
    isDirtyPhone: false,
    isDirtySms: false,
}
export const initialPhone3 = {
    id: null,
    number: "",
    sortOrder: 3,
    isPrimary: false,
    smsEnabled: false,

    // fields not part of the DTO
    isDirtyPhone: false,
    isDirtySms: false,
}
export const initialPhone4 = {
    id: null,
    number: "",
    sortOrder: 4,
    isPrimary: false,
    smsEnabled: false,

    // fields not part of the DTO
    isDirtyPhone: false,
    isDirtySms: false,
}
export const initialPhone5 = {
    id: null,
    number: "",
    sortOrder: 5,
    isPrimary: false,
    smsEnabled: false,

    // fields not part of the DTO
    isDirtyPhone: false,
    isDirtySms: false,
}