import React, { Suspense } from "react";
import Layout from './components/Layout/Layout';
import { BrowserRouter } from 'react-router-dom';
import Router from './components/Routes/Router';
import { RouteConfig } from './components/Routes/route-config'
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import axios from 'axios';
import setupAxios from './services/SetupAxios';
import { store } from './store/configureStore';
import ICurrentUser from "./Interfaces/ICurrentUser";
import { useAppDispatch, useAppSelector } from "./app/hook";
import { updateCurrentUser, setUserName } from "./store/slices/currentUserSlice";

import '@progress/kendo-theme-bootstrap/dist/all.css';
import './App.scss';
import { Client } from "./Api/client-api";
import { Loading } from "./components/Shared/Loading/Loading";

const api = new Client("", axios);

const App = ({ instance }: { instance: PublicClientApplication }) => {

    const currentUser = useAppSelector((state) => state.currentUser);
    const dispatch = useAppDispatch();

    const AuthenticateUser = () => {
        const accounts = instance.getAllAccounts();

        if (accounts.length) {
            if (accounts[0].name) {
                dispatch(setUserName(accounts[0].name));
            }

            instance.acquireTokenSilent({
                account: accounts[0],
                scopes: [`${process.env.REACT_APP_AZAD_SCOPE}`]
            }).then(response => {
                if (response.accessToken) {
                    const userUpdated: ICurrentUser = {
                        ...currentUser,
                        userId: response.account!.homeAccountId,
                        account: response.account!,
                        accessToken: response.accessToken,
                        accessTokenExpiresOn: response?.expiresOn?.toString() || null
                    };

                    dispatch(updateCurrentUser({ ...userUpdated }));
                    setupAxios(axios, store);
                    api.registerEmployee();
                    return response.accessToken;
                }
                return null;
            });
        }
    };

    React.useEffect(() => {
        if (currentUser.accessToken === "" || currentUser.accessToken === null || currentUser.accessToken === undefined) {
            AuthenticateUser();
        }
    });


    React.useEffect(() => {
        if (currentUser.accessToken && currentUser.accessToken !== "") {
            setupAxios(axios, store);
        }
    }, [currentUser.accessToken]);

    return (
        <BrowserRouter>
            <MsalProvider instance={instance}>
                <Layout>
                    <Suspense fallback={<Loading />}>
                        <Router routes={RouteConfig} msalInstance={instance}></Router>
                    </Suspense>
                </Layout>
            </MsalProvider>
        </BrowserRouter>

    );
}

export default App;