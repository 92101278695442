import * as PROFILE from './ResumeDetailsTab/ContactInfo/contactInfoConsts';
import {
    CandidateNameDto, CandidateEmailDto, CandidatePhoneDto
} from '../../../src/Api/client-api';
import { IContactInfo } from '../../Interfaces/IContactInfo';

export function setStateForNames(state: any, names: any): void {
    let primaryName = names.find((n: CandidateNameDto) => n.isPrimary) ?? null;
    let resumeName = names.find((n: CandidateNameDto) => n.isResumeName) ?? null;
    let nonResumeNames = names;

    if (primaryName !== null) {
        state.resumeDetails.contactInfo.preferredName = primaryName.fullName
        state.resumeDetails.contactInfo.primaryNameIndex = primaryName.alternateNameSortOrder ?? PROFILE.RESUMENAME;
    }

    if (resumeName) { // resume name doesn't have an alternateNameSortOrder
        state.resumeDetails.contactInfo.candidateNames[PROFILE.RESUMENAME] = resumeName;
        state.resumeDetails.contactInfo.candidateNames[PROFILE.RESUMENAME].isDirtyName = false;

        nonResumeNames = names.filter((n: CandidateNameDto) => !n.isResumeName);
    }

    for (let name of nonResumeNames) {
        let index = name.alternateNameSortOrder ?? 0;
        state.resumeDetails.contactInfo.candidateNames[index] = name;
        state.resumeDetails.contactInfo.candidateNames[index].isDirtyName = false;
    }

    // if user skips an alternate name, and fills in and saves name 4 before 3, or name 3 before 2, etc, we want to still show the name
    state.resumeDetails.contactInfo.altNamesCount
        = Math.max(
            ...state.resumeDetails.contactInfo.candidateNames.filter((n: any) => n.id !== null && !n.isResumeName && n.fullName.match(/^ *$/) === null)
            .map((n: any) => n.alternateNameSortOrder), 1);
}

export function setStateForEmails(state: any, emails: any): void {

    let primaryEmail = emails.find((n: CandidateEmailDto) => n.isPrimary) ?? null;

    if (primaryEmail !== null) {
        state.resumeDetails.contactInfo.preferredEmail = primaryEmail.email
        state.resumeDetails.contactInfo.primaryEmailIndex = primaryEmail.sortOrder - 1 ?? PROFILE.EMAIL1;
    }

    for (let email of emails) {
        let index = email.sortOrder - 1;
        state.resumeDetails.contactInfo.candidateEmails[index] = email;
        // TODO - this is already intiialized, so we need to reset?
        state.resumeDetails.contactInfo.candidateEmails[index].isDirtyEmail = false;
    }

    // if this is brand new candidate, show at least two email fields
    // if user skips an alternate name, and fills in and saves name 4 before 3, or name 3 before 2, etc, we want to still show the name
    state.resumeDetails.contactInfo.emailsCount
        = Math.max(
            ...state.resumeDetails.contactInfo.candidateEmails.filter((e: any) => e.id !== null && e.email.match(/^ *$/) === null)
            .map((e: any) => e.sortOrder), 2);
}

export function setStateForPhones(state: any, phones: any): void {

    let primaryPhone = phones.find((n: CandidatePhoneDto) => n.isPrimary) ?? null;

    if (primaryPhone !== null) {
        state.resumeDetails.contactInfo.preferredPhone = primaryPhone.number
        state.resumeDetails.contactInfo.primaryPhoneIndex = primaryPhone.sortOrder - 1 ?? PROFILE.PHONE1;
    }

    for (let phone of phones) {
        let index = phone.sortOrder - 1;
        state.resumeDetails.contactInfo.candidatePhones[index] = phone;
        // TODO - this is already intiialized, so we need to reset?
        state.resumeDetails.contactInfo.candidatePhones[index].isDirtyPhone = false;
        state.resumeDetails.contactInfo.candidatePhones[index].isDirtySms = false;
    }

    // if this is brand new candidate, show at least two email fields
    // if user skips an alternate name, and fills in and saves name 4 before 3, or name 3 before 2, etc, we want to still show the name
    state.resumeDetails.contactInfo.phonesCount
        = Math.max(
            ...state.resumeDetails.contactInfo.candidatePhones.filter((p: any) => p.id !== null && p.number.match(/^ *$/) === null)
            .map((p: any) => p.sortOrder), 2);
}

export function getUpdatedCandidateNames(contactInfo: IContactInfo, originalPrimaryNameIndex: number): any {
    let namesToUpdate = contactInfo.candidateNames.filter((n: any) => n.isDirtyName) ?? [];

    if (contactInfo.primaryNameIndex !== originalPrimaryNameIndex) {
        let primaryName = contactInfo.candidateNames[contactInfo.primaryNameIndex];

        // dont push the same name twice or push a brand new name that is also primary
        if(!namesToUpdate.some((n:any) => n.id === primaryName.id || n.id === null)) { 
            namesToUpdate.push(primaryName);
        }
    }

    return namesToUpdate;
}

export function getUpdatedCandidateEmails(contactInfo: IContactInfo, originalPrimaryEmailIndex: number): any {
    let emailsToUpdate = contactInfo.candidateEmails.filter((e: any) => e.isDirtyEmail) ?? [];

    if (contactInfo.primaryEmailIndex !== originalPrimaryEmailIndex) {
        let primaryEmail = contactInfo.candidateEmails[contactInfo.primaryEmailIndex];

        if(!emailsToUpdate.some((e:any) => e.id === emailsToUpdate.id || e.id === null)) {
            emailsToUpdate.push(primaryEmail);
        }
    }

    return emailsToUpdate;
}

export function getUpdatedCandidatePhones(contactInfo: IContactInfo, originalPrimaryPhoneIndex: number): any {
    let phonesToUpdate = contactInfo.candidatePhones.filter((e: any) => e.isDirtyPhone || e.isDirtySms) ?? [];

    if (contactInfo.primaryPhoneIndex !== originalPrimaryPhoneIndex) {
        let primaryPhone = contactInfo.candidatePhones[contactInfo.primaryPhoneIndex];

        if(!phonesToUpdate.some((p:any) => p.id === phonesToUpdate.id || p.id === null)) {
            phonesToUpdate.push(primaryPhone);
        }
    }

    return phonesToUpdate;
}