import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import {
    CandidateForExportDto,
    Client, GridCandidateDto, IUpdateGridCandidatesCommand, IUpdateGridCandidatesVm, UpdateGridCandidateDto, UpdateGridCandidatesCommand, UpdateGridCandidatesVm
} from '../../Api/client-api';

const api = new Client("", axios);

export interface IGridCandidates {
    candidates: GridCandidateDto[] | null,
    status: string
};

const initialState: IGridCandidates = {
    candidates: null,
    status: ''
};

export const getCandidatesGrid = createAsyncThunk(
    'Candidates/GetCandidatesGrid',
    async (showOldCandidates: boolean) => {
        let response = null;
        response = await (await api.getCandidatesGrid(showOldCandidates)).toJSON();
        return response;
    }
);

export const downloadCandidateResume = createAsyncThunk(
    'Candidates/DownloadCandidateResume?',
    async (candidateId: number) => {
        let response = null;
        response = await api.downloadCandidateResume(candidateId);
        return response.data;
    }
);

const pipedDates = (dates: string) => {
    let datesFormatted = ""
    const arrayOfDates = dates.split("|");
    arrayOfDates.forEach((d) => { datesFormatted += `${new Date(d).toLocaleString()} | ` });
    return datesFormatted.slice(0, -2);
}

const getCandidateDataForExport = (c: any) => {
    return {
        "Preferred Name": c.preferredName,
        "Preferred Email": c.preferredEmail,
        "Preferred Phone": c.preferredPhone,
        "CanMan Id": c.canManId,
        "Ocean7 Id": c.ocean7Id,
        "Henkel Id": c.henkelId,
        "Last Ocean7 Login Time": c.lastOcean7LoginTime,
        //"Pre SD Priority": c.preSDPriority,
        "Pool Rank": c.poolRank,
        "Profile Strength": c.resumeScore,
        "Profile Strength Confidence": c.resumeConfidence,
        "Recruiting Year": c.recruitingYear,
        "Gender": c.gender,
        "Race Ethnicity": c.raceEthnicity,
        "LGBTQ": c.lgbtq,
        "Consultant / Banker": c.consultantBanker,
        "Girls Who Invest": c.girlsWhoInvest,
        "MLT": c.mlt,
        "SEO": c.seo,
        "AIFP": c.aifp,
        "Synergist": c.synergist,
        "Out For Undergrad": c.outForUndergrad,
        "Vertical Interests": c.verticalInterests,
        "Current Employer": c.currentEmployer,
        "Employer Group": c.employerGroup,
        "UG School": c.ugSchool,
        "UG Year": c.ugYear,
        "UG GPA": c.ugGPA,
        "UG GPA Scale": c.ugMaxGPA,
        "GMAT": c.testGMAT,
        "SAT M": c.testSatMath,
        "SAT V": c.testSatVerbal,
        "SAT Total (1600)": c.testSatTotal1600,
        "SAT Total (2400)": c.testSatTotal2400,
        "ACT": c.testACT,
        "Leadership": c.leadership,
        "Athletics": c.athletics,
        "Grit": c.grit,
        "Work Authorization": c.workAuthorization,
        "Sector Experience": c.sectorExperience,
        "Is Target": c.isTarget,
        "Is Disqualified": c.isDisqualified,
        "CD&R Offer Extended": c.offerExtended,
        "CD&R Offer Date Time": c.offerDateTime ? new Date(c.offerDateTime).toLocaleString() : '',
        "PE Employer": c.peEmployer,
        "CD&R Offer Accepted": c.offerAccepted,
        "CD&R Offer Vertical": c.offerVertical,
        "Incoming": c.incoming,
        "Off The Market": c.offTheMarket,
        "Materially Updated": c.materiallyUpdated ? new Date(c.materiallyUpdated).toLocaleString() : '',
        "Last Updated": c.lastUpdated ? new Date(c.lastUpdated).toLocaleString() : '',
        "Event Count": c.eventCount,
        "Event Names": c.eventNames,
        "Coffee Chat 1 Eligibility": c.cc1Eligibility,
        "Coffee Chat 1 Status": c.cc1Status,
        "Coffee Chat 1 Date Time": c.cc1DateTime ? new Date(c.cc1DateTime).toLocaleString() : '',
        "Super Day Eligibility": c.sdEligibility,
        "Super Day Status": c.sdStatus,
        "Super Day Arrival Date Time": c.sdArrivalDateTime ? new Date(c.sdArrivalDateTime).toLocaleString() : '',
        "Coffee Chat #": c.totalCoffees ? c.totalCoffees : '',
        "Coffee Chat Avg": c.coffeesAverage,
        "Coffee Chat Score": c.coffeesScore,
        "Coffee Chat Interviewer Name": c.coffeesInterviewerName,
        "Coffee Chat Date": c.coffeesDate ? pipedDates(c.coffeesDate) : '',
        "Coffee Chat Feedback": c.coffeesFeedback,
        "BI 1 Score": c.bi1Score,
        "BI 1 Interviewer Name": c.bi1InterviewerName,
        "BI 1 Date": c.bi1Date ? new Date(c.bi1Date).toLocaleString() : '',
        "BI 1 Feedback": c.bi1Feedback,
        "Case Score": c.caseScore,
        "Case Interviewer Name": c.caseInterviewerName,
        "Case Date": c.caseDate ? new Date(c.caseDate).toLocaleString() : '',
        "Case Feedback": c.caseFeedback,
        "Informal LBO 1 Score": c.informalLboScore1,
        "Informal LBO 1 Interviewer": c.informalLboInterviewerName1,
        "Informal LBO 1 Date": c.informalLboDate1 ? new Date(c.informalLboDate1).toLocaleString() : '',
        "Informal LBO 1 Feedback": c.informalLboFeedback1,
        "Informal LBO 2 Score": c.informalLboScore2,
        "Informal LBO 2 Interviewer": c.informalLboInterviewerName2,
        "Informal LBO 2 Date": c.informalLboDate2 ? new Date(c.informalLboDate2).toLocaleString() : '',
        "Informal LBO 2 Feedback": c.informalLboFeedback2,
        "LBO Score": c.lboScore,
        "LBO Interviewer Name": c.lboInterviewerName,
        "LBO Date": c.lboDate ? new Date(c.lboDate).toLocaleString() : '',
        "LBO Feedback": c.lboFeedback,
        "BI 2 Score": c.bi2Score,
        "BI 2 Interviewer Name": c.bi2InterviewerName,
        "BI 2 Date": c.bi2Date ? new Date(c.bi2Date).toLocaleString() : '',
        "BI 2 Feedback": c.bi2Feedback,
        "BI 3 Score": c.bi3Score,
        "BI 3 Interviewer Name": c.bi3InterviewerName,
        "BI 3 Date": c.bi3Date ? new Date(c.bi3Date).toLocaleString() : '',
        "BI 3 Feedback": c.bi3Feedback,
        "BI 4 Score": c.bi4Score,
        "BI 4 Interviewer Name": c.bi4InterviewerName,
        "BI 4 Date": c.bi4Date ? new Date(c.bi4Date).toLocaleString() : '',
        "BI 4 Feedback": c.bi4Feedback,
        "Bonus Score": c.bonusScore,
        "Bonus Interviewer Name": c.bonusInterviewerName,
        "Bonus Date": c.bonusDate ? new Date(c.bonusDate).toLocaleString() : '',
        "Bonus Feedback": c.bonusFeedback,
        "Informal Feedback Submitter": c.informalFeedbackSubmitter,
        "Informal Feedback": c.informalFeedback,
        "Connection Level": c.connectionLevel,
        "Profile Note": c.profileNote,
        "Note 1": c.note1,
        "Note 2": c.note2,
        "Note 3": c.note3,
        "Note 4": c.note4,
        "Note 5": c.note5,
        "Note 6": c.note6,
        "Note 7": c.note7,
        "Note 8": c.note8,
        "Note 9": c.note9,
        "Note 10": c.note10,
        "Note 11": c.note11,
        "Note 12": c.note12,
        "Note 13": c.note13,
        "Note 14": c.note14,
        "Note 15": c.note15,
        "Note 16": c.note16,
        "Note 17": c.note17,
        "Note 18": c.note18,
    }
}

export const getCandidatesForExport = createAsyncThunk(
    'Candidates/GetCandidatesForExport',
    async (candidateIds: number[] | undefined) => {
        let response;
        if (candidateIds !== undefined && candidateIds.length > 0) {
            if (originalCandidatesForExport === null) {
                originalCandidatesForExport = (await api.getCandidatesForExport());
            }
            response = originalCandidatesForExport.candidates?.filter((c: CandidateForExportDto) => candidateIds.includes(c.canManId!)).map((c: CandidateForExportDto) => {
                return getCandidateDataForExport(c);
            });
        }
        else {
            response = [
                getCandidateDataForExport({})
            ];
        }
        return response;
    }
);


// isEndorsed and isLinked come from the server as booleans
// if there are no changes then the server expects null back
const transformField = (field: ("isEndorsed" | "isLinked"), updatedCandidateDto: UpdateGridCandidateDto, compareCandidate: UpdateGridCandidateDto, ogCandidate: UpdateGridCandidateDto): UpdateGridCandidateDto => {
    if (ogCandidate && compareCandidate) {
        if (
            (ogCandidate[field] == null && compareCandidate[field] === false) ||
            (compareCandidate[field] === ogCandidate[field])
        ) {
            updatedCandidateDto[field] = null;
        }
    }
    return updatedCandidateDto;
}

export const saveCandidateGridChanges = createAsyncThunk(
    'Candidates/PutCandidatesGrid',
    async (candidates: GridCandidateDto[], thunkAPI) => {
        let changedCandidates = new Array<UpdateGridCandidateDto>();
        let updated = false;
        originalCandidateGridState.forEach((ogCandidate: any) => {
            let compareCandidate = candidates.find((c) => c.canManId === ogCandidate.canManId);

            if (JSON.stringify(ogCandidate) !== JSON.stringify(compareCandidate)) {

                let updatedCandidateDto = new UpdateGridCandidateDto(compareCandidate);
                updated = true;

                if (compareCandidate) {
                    transformField("isEndorsed", updatedCandidateDto, compareCandidate, ogCandidate);
                    transformField("isLinked", updatedCandidateDto, compareCandidate, ogCandidate);
                }

                changedCandidates.push(updatedCandidateDto)
            }
        });

        if (changedCandidates && updated) {

            let updatedGridCandidates: IUpdateGridCandidatesVm = {
                candidates: changedCandidates
            }

            let updatedGridCandidatesVm = new UpdateGridCandidatesVm(updatedGridCandidates);

            let updateGridCandidatesCommandInterface: IUpdateGridCandidatesCommand = {
                candidatesVm: updatedGridCandidatesVm
            }

            let putCommand: UpdateGridCandidatesCommand = new UpdateGridCandidatesCommand(updateGridCandidatesCommandInterface)
            await api.putCandidatesGrid(putCommand);
        }

    }
);

export const deleteCandidate = createAsyncThunk(
    'Candidates/DeleteCandidate',
    async (id: number | undefined) => {
        await api.deleteCandidate(id);
    }
);

export let originalCandidateGridState: any;
export let originalCandidatesForExport: any;

export const candidateSlice = createSlice({
    name: 'candidatesGrid',
    initialState,
    reducers: {
        setCandidatesGrid: (state, action) => {
        },
        updateCandidatesGrid: (state, action) => {
            state.candidates = action.payload;

        },
        doDeleteCandidate: (state, action) => {
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCandidatesGrid.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getCandidatesGrid.fulfilled, (state, action) => {
                if (action?.payload) {
                    originalCandidateGridState = cloneDeep(action.payload.candidates);
                    originalCandidatesForExport = null;
                    state.candidates = action.payload.candidates
                    state.status = 'idle';
                }
                // TODO handle error
            })
            .addCase(getCandidatesGrid.rejected, (state, action) => {
                state.status = 'idle';
            })
            .addCase(getCandidatesForExport.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getCandidatesForExport.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(getCandidatesForExport.rejected, (state, action) => {
                state.status = 'idle';
            })
            .addCase(deleteCandidate.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteCandidate.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(deleteCandidate.rejected, (state, action) => {
                state.status = 'idle';
            })
            .addCase(saveCandidateGridChanges.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(saveCandidateGridChanges.fulfilled, (state, action) => {
                originalCandidateGridState = action.meta.arg;
                originalCandidatesForExport = null;
                state.candidates = action.meta.arg;
                state.status = 'idle';
            })
            .addCase(saveCandidateGridChanges.rejected, (state) => {
                state.status = 'idle';

            })
    },
});

export const { setCandidatesGrid, updateCandidatesGrid, doDeleteCandidate } = candidateSlice.actions

export default candidateSlice.reducer;