import React, { useCallback } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../services/authConfig";
import { Button } from "@progress/kendo-react-buttons";
import { CandidateSearchBox } from "../Candidates/CandidateSearchBox"
import { AccountInfo, InteractionStatus, IPublicClientApplication } from "@azure/msal-browser";
import ICurrentUser from "../../Interfaces/ICurrentUser";
import { useAppDispatch, useAppSelector } from "../../app/hook";
import { updateCurrentUser, setUserName } from "../../store/slices/currentUserSlice";

/**
 * Renders either a login or logout button depending on the user's authenticated status
 */
const SignInButton = () => {
    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const currentUser = useAppSelector((state) => state.currentUser);
    const dispatch = useAppDispatch();



    const setAccessTokenAndCurrentUser = React.useCallback((inProgress: InteractionStatus, accounts: AccountInfo[], instance: IPublicClientApplication) => () => {
        if (inProgress === "none" && accounts.length > 0) {
            // Retrieve an access token
            instance.acquireTokenSilent({
                account: accounts[0],
                scopes: [`${process.env.REACT_APP_AZAD_SCOPE}`]
            }).then(response => {
                if (response.accessToken) {
                    const userUpdated: ICurrentUser = {
                        ...currentUser,
                        accessToken: response.accessToken,
                        accessTokenExpiresOn: response?.expiresOn?.toString() || null
                    };
                    dispatch(updateCurrentUser({ ...userUpdated }));
                    return response.accessToken;
                }
                return null;
            });
        }
    }, [dispatch, currentUser]);

    React.useEffect(() => {
        setAccessTokenAndCurrentUser(inProgress, accounts, instance);
    }, [inProgress, accounts, instance, setAccessTokenAndCurrentUser]);

    const getAccountInfo =
        useCallback(async () => {
            const accounts = instance.getAllAccounts();
            if (accounts.length) {
                if (accounts[0].name) {
                    dispatch(setUserName(accounts[0].name));
                }
            }
        }, [dispatch, instance]);

    React.useEffect(() => {
        getAccountInfo();
    }, [isAuthenticated, getAccountInfo]);

    function handleLogin(instance: IPublicClientApplication) {
        instance.loginPopup(loginRequest).catch(e => {
            console.error(e);
        });
    }

    function handleLogout(instance: IPublicClientApplication) {
        window.localStorage.clear();
        instance.logoutPopup().catch(e => {
            console.error(e);
        });
    }

    return (
        <div className="user-login">
            {isAuthenticated === true &&
                <>
                    <CandidateSearchBox />
                    <span className="user-name">{currentUser.name}</span>
                    <Button fillMode="flat" className="logout" onClick={() => handleLogout(instance)}>Logout</Button>
                </>
            }
            {isAuthenticated === false &&
                <Button fillMode="flat" className="login" onClick={() => handleLogin(instance)}>Login</Button>
            }
        </div>
    );
}

export default SignInButton;