import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import {
    ActiveDirectoryUserDto, Client, GridEmployeeDto, ICreateEmployeeCommand, CreateEmployeeCommand,
    IUpdateActiveDirectoryUserDto, UpdateActiveDirectoryUserDto, EmployeeForExportDto
} from '../../Api/client-api';

const api = new Client("", axios);

export interface IGridEmployee {
    employees: GridEmployeeDto[] | null,
    status: string
};

const initialState: IGridEmployee = {
    employees: null,
    status: ''
};

export const getEmployeeGrid = createAsyncThunk(
    'Employee/getEmployeeGrid',
    async () => {
        let response = null;
        response = await (await api.getEmployeeGrid()).toJSON();
        return response;
    }
);

export const getActiveDirectoryUsersLookup = createAsyncThunk(
    'employee/fetchgetActiveDirectoryUsersLookup',
    async () => {
        let response = await (await api.getActiveDirectoryUsersLookup()).toJSON();

        return response;
    }
);

export const getEmployeesForExport = createAsyncThunk(
    'Employees/GetEmployeesForExport',
    async (employeeIds: number[] | undefined) => {
        let response = null;

        if (employeeIds !== undefined && employeeIds.length > 0) {
            if (originalEmployeesForExport === null) {
                originalEmployeesForExport = (await api.getEmployeesForExport());
            }

            response = originalEmployeesForExport.employees?.filter((c: EmployeeForExportDto) => employeeIds.includes(c.employeeId!)).map((c: EmployeeForExportDto) => {
                return {
                    "Full Name": c.fullName,
                    "Alternate Name": c.alternateFullName,
                    "Employee Id": c.employeeId,
                    "Active": c.active,
                    "Interviewing": c.interviewing,
                    "Employee Level": c.employeeLevelName,
                    "Email": c.preferredEmail,
                    "Hometown": c.homeTown,
                    "Hometown Zip Code": c.zipcode,
                    "High School": c.highSchool,
                    "Previous Employer": c.preEmployerFirm,
                    "Previous Employer Group": c.preEmployerGroup,
                    "UG School": c.ugSchool,
                    "UG Major": c.ugMajor,
                    "Vertical Alignment": c.verticalInterests,
                    "Gender": c.gender,
                    "Race / Ethnicity": c.raceEthnicity,
                    "LGBTQ": c.lgbtq,
                    "Girls Who Invest": c.girlsWhoInvest,
                    "MLT": c.mlt,
                    "SEO": c.seo,
                    "AIFP": c.aifp,
                    "Synergist": c.synergist,
                    "Out For Undergrad": c.outForUndergrad,
                    "BI Trailing 12 Avg": c.scoringStatistics?.biScore12MonthTrailingAverage?.toFixed(2),
                    "BI Trailing 12 #": c.scoringStatistics?.biCount12MonthTrailing,
                    "BI All Time Avg": c.scoringStatistics?.biScoreTotalAverage?.toFixed(2),
                    "BI All Time #": c.scoringStatistics?.biCountTotal,
                    "LBO Trailing 12 Avg": c.scoringStatistics?.lboScore12MonthTrailingAverage?.toFixed(2),
                    "LBO Trailing 12 #": c.scoringStatistics?.lboCount12MonthTrailing,
                    "LBO All Time Avg": c.scoringStatistics?.lboScoreTotalAverage?.toFixed(2),
                    "LBO All Time #": c.scoringStatistics?.lboCountTotal,
                    "Case Trailing 12 Avg": c.scoringStatistics?.caseScore12MonthTrailingAverage?.toFixed(2),
                    "Case Trailing 12 #": c.scoringStatistics?.caseCount12MonthTrailing,
                    "Case All Time Avg": c.scoringStatistics?.caseScoreTotalAverage?.toFixed(2),
                    "Case All Time #": c.scoringStatistics?.caseCountTotal,
                    "Bonus Trailing 12 Avg": c.scoringStatistics?.bonusScore12MonthTrailingAverage?.toFixed(2),
                    "Bonus Trailing 12 #": c.scoringStatistics?.bonusCount12MonthTrailing,
                    "Bonus All Time Avg": c.scoringStatistics?.bonusScoreTotalAverage?.toFixed(2),
                    "Bonus All Time #": c.scoringStatistics?.bonusCountTotal,
                    "Last Updated": c.lastUpdated,
                }
            });
        }

        return response;
    }
);

export const saveEmployeeAsync = createAsyncThunk(
    'employee/saveEmployee',
    async (employeeSelected: ActiveDirectoryUserDto, thunkAPI) => {
        let response = null;
        let iUpdateActiveDirectoryUserDto: IUpdateActiveDirectoryUserDto = {
            id: employeeSelected.id,
            displayName: employeeSelected.displayName,
            userPrincipalName: employeeSelected.userPrincipalName
        }
        let updateActiveDirectoryUserDto = new UpdateActiveDirectoryUserDto(iUpdateActiveDirectoryUserDto);

        let iCreateEmployeeCommand: ICreateEmployeeCommand = {
            employee: updateActiveDirectoryUserDto
        }

        let createCommand: CreateEmployeeCommand = new CreateEmployeeCommand(iCreateEmployeeCommand);
        response = await api.createEmployee(createCommand);

        return response;
    }
);

export let originalEmployeeGridState: any;
export let originalEmployeesForExport: any;

export const employeeSlice = createSlice({
    name: 'employeesGrid',
    initialState,
    reducers: {
        setEmployeesGrid: (state, action) => {
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEmployeeGrid.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getEmployeeGrid.fulfilled, (state, action) => {
                if (action?.payload) {
                    originalEmployeeGridState = cloneDeep(action.payload.employees);
                    originalEmployeesForExport = null;
                    state.employees = action.payload.employees
                    state.status = 'idle';
                }
                // TODO handle error
            })
            .addCase(getEmployeeGrid.rejected, (state, action) => {
                state.status = 'idle';
            })
            .addCase(getActiveDirectoryUsersLookup.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getActiveDirectoryUsersLookup.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(getActiveDirectoryUsersLookup.rejected, (state, action) => {
                state.status = 'idle';
            })
    },
});

export const { setEmployeesGrid } = employeeSlice.actions

export default employeeSlice.reducer;