import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import {
    Client, SuperDayCandidateDto,
    UpdateSuperDayGridDto, IUpdateSuperDayGridVm, UpdateSuperDayGridVm, IUpdateSuperDayGridCommand, UpdateSuperDayGridCommand

} from '../../Api/client-api';

const api = new Client("", axios);

export interface ISuperDayCandidates {
    status: string;
    candidates?: SuperDayCandidateDto[]
};

const initialState: ISuperDayCandidates = {
    candidates: undefined,
    status: ''
};

export const getSuperDayGrid = createAsyncThunk(
    'SuperDay/GetSuperDayGrid',
    async () => {
        let response = null;

        response = await (await api.getSuperDayGrid()).toJSON();
        return response;
    }
);

export const downloadCandidateResume = createAsyncThunk(
    'Candidates/DownloadCandidateResume?',
    async (candidateId: number) => {
        let response = null;
        response = await api.downloadCandidateResume(candidateId);
        return response.data;
    }
);

export const saveSuperDayGridChanges = createAsyncThunk(
    'SuperDay/PutCandidatesGrid',
    async (candidates: SuperDayCandidateDto[], thunkAPI) => {
        let changedCandidates = new Array<UpdateSuperDayGridDto>();
        let updated = false;
        originalCandidateGridState.forEach((ogCandidate: any) => {
            let compareCandidate = candidates.find((c) => c.canManId === ogCandidate.canManId);

            if (JSON.stringify(ogCandidate) !== JSON.stringify(compareCandidate)) {

                let updatedCandidateDto = new UpdateSuperDayGridDto(compareCandidate);
                updated = true;

                changedCandidates.push(updatedCandidateDto)
            }
        });

        if (changedCandidates && updated) {

            let updatedGridCandidates: IUpdateSuperDayGridVm = {
                candidates: changedCandidates
            }

            let updatedGridCandidatesVm = new UpdateSuperDayGridVm(updatedGridCandidates);

            let updateGridCandidatesCommandInterface: IUpdateSuperDayGridCommand = {
                superDayGridVm: updatedGridCandidatesVm
            }

            let putCommand: UpdateSuperDayGridCommand = new UpdateSuperDayGridCommand(updateGridCandidatesCommandInterface)
            await api.putCandidatesGrid3(putCommand);
        }

    }
);

let originalCandidateGridState: any;

export const superDaySlice = createSlice({
    name: 'superDayGrid',
    initialState,
    reducers: {
        updateSuperDayGrid: (state, action) => {
            state.candidates = action.payload;

        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSuperDayGrid.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getSuperDayGrid.fulfilled, (state, action) => {
                if (action.payload.candidates) {
                    originalCandidateGridState = cloneDeep(action.payload.candidates);
                    state.candidates = action.payload.candidates;
                    state.status = 'idle';
                }
            })
            .addCase(getSuperDayGrid.rejected, (state, action) => {
                state.status = 'idle';
            })
    },
});

export const { updateSuperDayGrid } = superDaySlice.actions

export default superDaySlice.reducer;