import { createSlice } from '@reduxjs/toolkit';
import { AccountInfo } from '@azure/msal-browser';
import ICurrentUser from '../../Interfaces/ICurrentUser';

/**
 * The uninitalized state of the Microsoft Authentication Library (MSAL) AccountInfo.
 */
export const emptyMsalAccountInfo: AccountInfo = {
    environment: '',
    homeAccountId: '',
    localAccountId: '',
    tenantId: '',
    username: ''
};

const initialState = {
    userId: '',
    email: '',
    name: '',
    accessToken: '',
    accessTokenExpiresOn: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toString(),
    account: { ...emptyMsalAccountInfo }
} as ICurrentUser

export const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState,
    reducers: {
        updateCurrentUser: (state, action) => {
            return { ...action.payload }
        },
        setUserName: (state, action) => {
            state.name = action.payload
        }
    },
    extraReducers: (builder) => { },
});

export const { updateCurrentUser, setUserName } = currentUserSlice.actions;

export default currentUserSlice.reducer;