import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { AutomatedInterviewDto, Client, CreateAutomatedInterviewCommand, ICreateAutomatedInterviewCommand, IUpdateAutomatedInterviewCommand, SchedulingCandidateDto, SchedulingLboStationsDto, UpdateAutomatedInterviewCommand } from '../../../Api/client-api';

const api = new Client("", axios);

export interface ILboFormData {
    interviewStationId: number | null,
    schedulingEvent: number | null,
    candidate: number | null,
    startTime: string | null,
    endTime: string | null,
    didShowUp: boolean | null,
}

export interface ILboScheduler {
    status: string,
    lboStations: SchedulingLboStationsDto[] | null,
    schedulingCandidates: SchedulingCandidateDto[] | null,
    lboFormData: ILboFormData
}

const initialState: ILboScheduler = {
    lboStations: null,
    status: '',
    schedulingCandidates: null,
    lboFormData: {
        interviewStationId: null,
        candidate: null,
        startTime: null,
        endTime: null,
        schedulingEvent: null,
        didShowUp: null,
    },
};

export const getSchedulerLboStations = createAsyncThunk(
    'Scheduling/GetLboStations',
    async () => {
        let response = await (await api.getSchedulingLboStations()).toJSON();
        response.lboStations.forEach(function (e: any) {
            e.text = e.name;   // Required to list lbo stations in the scheduler's y-axis
        });
        return response;
    }
);

export const createAutomatedInterview = createAsyncThunk(
    'Scheduling/CreateAutomatedInterview',
    async (AutomatedInterview: any) => {
        let automatedInterviewDto = new AutomatedInterviewDto(AutomatedInterview);
        let createAutomatedInterviewCommand: ICreateAutomatedInterviewCommand = {
            automatedInterview: automatedInterviewDto
        }
        let createAutomatedCommand: CreateAutomatedInterviewCommand = new CreateAutomatedInterviewCommand(createAutomatedInterviewCommand);
        let response = await (await api.createAutomatedInterview(createAutomatedCommand)).toJSON();
        return response;
    }
)

export const updateAutomatedInterview = createAsyncThunk(
    'Scheduling/UpdateAutomatedInterview',
    async (AutomatedInterview: any) => {
        let UpdatedAutomatedInterview = new AutomatedInterviewDto(AutomatedInterview);
        let updateAutomatedInterviewCommand: IUpdateAutomatedInterviewCommand = {
            automatedInterview: UpdatedAutomatedInterview
        }
        let updatedAutomatedInterviewCommand: UpdateAutomatedInterviewCommand = new UpdateAutomatedInterviewCommand(updateAutomatedInterviewCommand);
        let response = await (await api.updateAutomatedInterview(updatedAutomatedInterviewCommand)).toJSON();
        return response;
    }
)

export const lboSchedulingSlice = createSlice({
    name: 'lboScheduling',
    initialState,
    reducers: {
        getSchedulerLboStations: (state, action) => {
            state.lboStations = action.payload
        },
        updateLboStationEditor: (state, action) => {
            state.lboFormData.interviewStationId = action.payload
        },

        updateCandidateEditor: (state, action) => {
            state.lboFormData.candidate = action.payload
        },

        updateStartTime: (state, action) => {
            state.lboFormData.startTime = action.payload
        },

        updateEndTime: (state, action) => {
            state.lboFormData.endTime = action.payload
        },

        updateShowUp: (state, action) => {
            state.lboFormData.didShowUp = action.payload
        },

        getSchedulerCandidates: (state, action) => {
            state.schedulingCandidates = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSchedulerLboStations.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getSchedulerLboStations.fulfilled, (state, action) => {
                state.status = 'idle';
                state.lboStations = action.payload.lboStations
            })
            .addCase(getSchedulerLboStations.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(createAutomatedInterview.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createAutomatedInterview.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(createAutomatedInterview.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(updateAutomatedInterview.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateAutomatedInterview.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(updateAutomatedInterview.rejected, (state) => {
                state.status = 'idle';

            })
    },
});

export const { updateLboStationEditor, updateCandidateEditor, updateStartTime, updateEndTime, updateShowUp } = lboSchedulingSlice.actions;

export default lboSchedulingSlice.reducer;