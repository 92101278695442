import {
    IResumeContactInfoDto, IUpdateResumeDetailsDto,
    ResumeEmailDto, UpdateResumeDetailsDto, ResumeContactInfoDto, ResumeNameDto, 
    ResumePhoneDto, CandidateIdentifierDto, CandidateCurrentEmploymentDto, CandidateEducationDto, CandidateStandardizedTestsDto, CandidateOtherConsiderationsDto, CandidateVerticalInterestDto, CandidateLocationDto, CandidateDiversityDto, CandidatePeRecruitingYearDto, CandidateAffiliationDto, CandidateJobOfferDto
} from '../../../Api/client-api';
import { IContactInfo } from '../../../Interfaces/IContactInfo';
import { IResumeDetails } from '../../../Interfaces/IResumeDetails'

function buildContactInfo(updatedContactInfo: IContactInfo): ResumeContactInfoDto | undefined {
    let updatedNames: ResumeNameDto[] | null = null;
    let updatedEmails: ResumeEmailDto[] | null = null;
    let updatedPhones: ResumePhoneDto[] | null = null;

    if (updatedContactInfo?.candidateNames) {
        updatedNames = [];
        for (let name of updatedContactInfo.candidateNames) {
            updatedNames.push(new ResumeNameDto(name));
        }
    }

    if (updatedContactInfo?.candidateEmails) {
        updatedEmails = [];
        for (let email of updatedContactInfo.candidateEmails) {
            updatedEmails.push(new ResumeEmailDto(email));
        }
    }

    if (updatedContactInfo?.candidatePhones) {
        updatedPhones = [];
        for (let phone of updatedContactInfo.candidatePhones) {
            updatedPhones.push(new ResumePhoneDto(phone));
        }
    }

    let contactInfo = undefined;
    if (updatedNames !== null || updatedEmails !== null || updatedPhones !== null) {
        let contact: IResumeContactInfoDto = {
            candidateNames: updatedNames,
            candidateEmails: updatedEmails,
            candidatePhones: updatedPhones,
        }

        contactInfo =  new ResumeContactInfoDto(contact);
    }

    return contactInfo;
}

export function mapResumeDetailsUpdates(resumeDetailsUpdates: IResumeDetails): UpdateResumeDetailsDto {
    let updatedContactInfo: ResumeContactInfoDto | undefined = undefined;
    let newIdentifier: CandidateIdentifierDto | undefined = undefined;
    let newLocation: CandidateLocationDto | undefined = undefined;
    let newCurrentEmployment: CandidateCurrentEmploymentDto | undefined = undefined;
    let newEducation: CandidateEducationDto | undefined = undefined;
    let newTests: CandidateStandardizedTestsDto | undefined = undefined;
    let newConsiderations: CandidateOtherConsiderationsDto | undefined = undefined;
    let newVerticalInterest: CandidateVerticalInterestDto | undefined = undefined;
    let newDiversity: CandidateDiversityDto | undefined = undefined;
    let newAffiliation: CandidateAffiliationDto | undefined = undefined;
    let newPeRecruitingYear: CandidatePeRecruitingYearDto | undefined = undefined;
    let newJobOffer: CandidateJobOfferDto | undefined = undefined;

    if (resumeDetailsUpdates?.contactInfo) {
        updatedContactInfo = buildContactInfo(resumeDetailsUpdates.contactInfo);
    }

    if (resumeDetailsUpdates?.identifier) {
        newIdentifier = new CandidateIdentifierDto(resumeDetailsUpdates.identifier);
    }

    if (resumeDetailsUpdates?.location) {
        newLocation = new CandidateLocationDto(resumeDetailsUpdates.location);
    }

    if (resumeDetailsUpdates?.currentEmployment) {
        newCurrentEmployment = new CandidateCurrentEmploymentDto(resumeDetailsUpdates.currentEmployment);
    }

    if (resumeDetailsUpdates?.education) {
        newEducation = new CandidateEducationDto(resumeDetailsUpdates.education);
    }

    if (resumeDetailsUpdates?.standardizedTests) {
        newTests = new CandidateStandardizedTestsDto(resumeDetailsUpdates.standardizedTests);
    }

    if (resumeDetailsUpdates?.otherConsiderations) {
        newConsiderations = new CandidateOtherConsiderationsDto(resumeDetailsUpdates.otherConsiderations);
    }

    if (resumeDetailsUpdates?.verticalInterest) {
        newVerticalInterest = new CandidateVerticalInterestDto(resumeDetailsUpdates.verticalInterest);
    }

    if (resumeDetailsUpdates?.diversity) {
        newDiversity = new CandidateDiversityDto(resumeDetailsUpdates.diversity);
    }

    if (resumeDetailsUpdates?.affiliation) {
        newAffiliation = new CandidateAffiliationDto(resumeDetailsUpdates.affiliation);
    }

    if (resumeDetailsUpdates?.peRecruitingYear) {
        newPeRecruitingYear = new CandidatePeRecruitingYearDto(resumeDetailsUpdates.peRecruitingYear);
    }

    if (resumeDetailsUpdates?.jobOffer) {
        if (resumeDetailsUpdates.jobOffer?.offerDateTime) {
            resumeDetailsUpdates.jobOffer.offerDateTime = new Date(resumeDetailsUpdates.jobOffer.offerDateTime).toISOString();
        }
        newJobOffer = new CandidateJobOfferDto(resumeDetailsUpdates.jobOffer);
    }

    let resDetails: IUpdateResumeDetailsDto = {
        contactInfo: updatedContactInfo,
        identifier: newIdentifier,
        location: newLocation,
        currentEmployment: newCurrentEmployment,
        education: newEducation,
        standardizedTests: newTests,
        otherConsiderations: newConsiderations,
        verticalInterest: newVerticalInterest,
        diversity: newDiversity,
        affiliation: newAffiliation,
        peRecruitingYear: newPeRecruitingYear,
        jobOffer: newJobOffer,
    }

    let resumeDetailsDto = new UpdateResumeDetailsDto(resDetails);

    return resumeDetailsDto;
}