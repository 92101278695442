import { useMemo, useState } from "react";
import axios from 'axios';
import { Alert } from "reactstrap";

const ErrorAlert = (props: any) => {
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>("");
    const [alertColor, setAlertColor] = useState<string>("danger");

    useMemo(() => {
        axios.interceptors.response.use(
            (next) => {
                return Promise.resolve(next);
            },
            (error) => {
                setShowAlert(true);
                setAlertColor("danger");

                if (error.response) {
                    if (typeof error.response.data === 'string') {
                        if (error.response.status === 409) {
                            setAlertColor("warning");
                        }
                        setAlertMessage(error.response?.data.split('\n').map((item: any, i: any) => <div key={i}>{item}</div>));
                    } else if (error.response.data?.title) {
                        setAlertMessage(error.response.data?.title);
                    } else {
                        setAlertMessage(error.response.statusText);
                    }
                } else if (error.request) {
                    setAlertMessage(error.request.statusText);
                } else {
                    setAlertMessage(error.message);
                }

                return Promise.reject(error);
            }
        )
    }, [setShowAlert, setAlertMessage])

    const handleAlertDismiss = () => {
        setShowAlert(false);
    };

    return (
        <>
            {showAlert &&
                <Alert color={alertColor} isOpen={showAlert} toggle={handleAlertDismiss}>
                    {alertMessage}
                </Alert>
            }
        </>
    )
}

export default ErrorAlert
