import { CandidateSchedulingWorkflowDto, CandidateJobOfferDto, IUpdateSchedulingDto, UpdateSchedulingDto } from "../../../Api/client-api";
import { IScheduling } from "../../../Interfaces/IScheduling";

export function mapSchedulingUpdates(schedulingUpdates: IScheduling): UpdateSchedulingDto {
    let newSuperDaySchedulingWorkflow: CandidateSchedulingWorkflowDto | undefined = undefined;
    let newJobOffer: CandidateJobOfferDto | undefined = undefined;
    let newCc1SchedulingWorkflow: CandidateSchedulingWorkflowDto | undefined = undefined;

    if (schedulingUpdates?.superDaySchedulingWorkflow) {
        if (schedulingUpdates.superDaySchedulingWorkflow?.arrivalDateTime) {
            schedulingUpdates.superDaySchedulingWorkflow.arrivalDateTime = new Date(schedulingUpdates.superDaySchedulingWorkflow.arrivalDateTime).toISOString();
        }
        newSuperDaySchedulingWorkflow = new CandidateSchedulingWorkflowDto(schedulingUpdates.superDaySchedulingWorkflow);
    }

    if (schedulingUpdates?.jobOffer) {
        if (schedulingUpdates.jobOffer?.offerDateTime) {
            schedulingUpdates.jobOffer.offerDateTime = new Date(schedulingUpdates.jobOffer.offerDateTime).toISOString();
        }
        newJobOffer = new CandidateJobOfferDto(schedulingUpdates.jobOffer);
    }

    if (schedulingUpdates?.cc1SchedulingWorkflow) {
        if (schedulingUpdates.cc1SchedulingWorkflow?.arrivalDateTime) {
            schedulingUpdates.cc1SchedulingWorkflow.arrivalDateTime = new Date(schedulingUpdates.cc1SchedulingWorkflow.arrivalDateTime).toISOString();
        }
        newCc1SchedulingWorkflow = new CandidateSchedulingWorkflowDto(schedulingUpdates.cc1SchedulingWorkflow);
    }

    let updatedProfileOverview: IUpdateSchedulingDto = {
        superDaySchedulingWorkflow: newSuperDaySchedulingWorkflow,
        jobOffer: newJobOffer,
        cc1SchedulingWorkflow: newCc1SchedulingWorkflow
    }

    let schedulingDto = new UpdateSchedulingDto(updatedProfileOverview);

    return schedulingDto;
}