import React from 'react';
import { NavLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { RouteConfig } from '../Routes/route-config'

const Breadcrumb = () => {
    const breadcrumbs = useBreadcrumbs(RouteConfig);

    return (
        <div className="breadcrumb">
            {breadcrumbs.map(({
                match,
                breadcrumb
            }) => (
                <span key={match.url}>
                    <NavLink to={match.url}>{breadcrumb}</NavLink>
                </span>
            ))}
        </div>
    );
}

export default Breadcrumb;
